export enum ViewType {
  InstitutionsDetails = 1,
  Students,
  Payments,
  Docs,
  Audit
}

export enum StudentTab {
  All = 1,
  Approved,
  Duplicate,
  Rejected
}
