import {Navigate, useLocation} from 'react-router-dom'
import {useMainStore} from 'src/contexts/Main'
import {PATH_AUTH} from 'src/routes/paths'
import CuiLoading from '../custom/CuiLoading'
import {observer} from 'mobx-react-lite'

const ProtectedRoute = observer(function ProtectedRoute({children}: any) {
  const location = useLocation()
  const {loginStore} = useMainStore()

  if (loginStore.isAuth) return children

  if (loginStore.isAccessDenied) return <Navigate to={PATH_AUTH.accessDenied} />

  if (loginStore.isMsalAuth && !loginStore.shouldLogout) {
    return <CuiLoading />
  }

  return <Navigate to="/login" state={{from: location.pathname}} />
})

export default ProtectedRoute
