import {Box, Typography} from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  width?: string | number
}

const TabPanel: React.FC<TabPanelProps> = ({children, value, index, width}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{p: 3, pb: 5, width: width ?? '100%'}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default TabPanel
