import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Box
} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {observer} from 'mobx-react-lite'
import {grey} from '@mui/material/colors'
import {ChangeEvent, useState} from 'react'

const MAX_INT = 2147483647

interface SendPaymentEmailDialogProps {
  onClose: () => void
  onSave: (paymentReportId: number | undefined) => Promise<void>
}

const SendPaymentEmailDialog = observer(
  ({onClose, onSave}: SendPaymentEmailDialogProps) => {
    const {
      isLoadingSendPaymentEmail,
      setIsLoadingSendPaymentEmail,
      isInvalidPaymentReportId,
      setIsInvalidPaymentReportId
    } = useMainStore().paymentsStore
    const [currentPaymentReportId, setCurrentPaymentReportId] = useState<
      number | undefined
    >(undefined)

    const [isInvalidNumber, setIsInvalidNumber] = useState<boolean>(false)

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      setIsInvalidNumber(false)
      const value = Number(event.target.value)
      if (value <= MAX_INT) {
        setCurrentPaymentReportId(Number(event.target.value))
        setIsInvalidPaymentReportId(false)
      } else {
        setCurrentPaymentReportId(undefined)
        setIsInvalidNumber(true)
      }
    }

    const handleSave = async () => {
      setIsLoadingSendPaymentEmail(true)
      await onSave(currentPaymentReportId)
    }

    return (
      <Dialog open={true}>
        <DialogTitle>
          <Typography variant="h5" align="center">
            שליחת מייל
          </Typography>
        </DialogTitle>
        <DialogContent sx={{pt: '16px !important', pb: '0px !important'}}>
          <Typography variant="body1" color={grey[700]} align="center">
            הקלד את מס’ הדו”ח שברצונך לשלוח:
            <Box sx={{mt: 2, mb: 2}}>
              <TextField
                type="number"
                label="מס’ דו”ח"
                error={isInvalidPaymentReportId || isInvalidNumber}
                helperText={
                  isInvalidPaymentReportId
                    ? 'מספר דוח לא קיים במערכת'
                    : isInvalidNumber
                    ? 'מספר לא תקין'
                    : ''
                }
                inputProps={{max: MAX_INT}}
                value={currentPaymentReportId}
                onChange={handleTextChange}
              />
            </Box>
            בפעולה זו ישלח מייל לכל אנשי הקשר של המוסדות המעדכן
            <br />
            על ביצוע העברת תשלום. האם ברצונך לשלוח דו”ח?
          </Typography>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
          <Button
            disabled={isLoadingSendPaymentEmail}
            onClick={() => onClose()}
            variant="contained"
            fullWidth
            sx={{m: 1}}
            color="inherit"
          >
            לא
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            fullWidth
            disabled={
              isLoadingSendPaymentEmail || currentPaymentReportId === undefined
            }
          >
            כן
            {isLoadingSendPaymentEmail && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  fontWeight: 'bold',
                  marginTop: -1.5,
                  marginLeft: -1.5
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
export default SendPaymentEmailDialog
