function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = ''
const ROOTS_KOT = ''

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  logout: path(ROOTS_AUTH, '/logout'),
  accessDenied: path(ROOTS_AUTH, '/accessDenied')
}

export const PATH_KOT = {
  root: ROOTS_KOT,
  institution: path(ROOTS_KOT, '/institutions/:institutionId')
}
