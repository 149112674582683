import {Button, Stack} from '@mui/material'
import {
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  useGridApiContext
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import DownloadIcon from '@mui/icons-material/Download'

const PaymentsToolBar = observer(({setFilterButtonEl}: GridToolbarProps) => {
  const apiRef = useGridApiContext()

  const downloadExcel = () => {
    const now = new Date()
    const fileName =
      'דוחות תשלום ' +
      '_' +
      now.getFullYear() +
      '_' +
      Number(now.getMonth() + 1) +
      '_' +
      now.getDate()

    apiRef.current.exportDataAsCsv({utf8WithBom: true, fileName: fileName})
  }

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton ref={setFilterButtonEl} />
        </Stack>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            sx={{ml: 1.5}}
            variant="outlined"
            color="inherit"
            startIcon={<DownloadIcon sx={{ml: 1, mr: -1}} />}
            onClick={downloadExcel}
          >
            {' '}
            הורדה{' '}
          </Button>
        </Stack>
      </Stack>
    </>
  )
})
export default PaymentsToolBar
