import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {useTheme} from '@mui/material/styles'
import {ReactComponent as Logo} from 'src/images/logo.svg'
import {Stack} from '@mui/material'
import CuiAvatar from '../custom/CuiAvatar'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'

// ----------------------------------------------------------------------

const Header = () => {
  const {
    loginStore: {currentUser}
  } = useMainStore()
  const theme = useTheme()

  return (
    <AppBar
      sx={{
        position: 'static',
        boxShadow: 'none',
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter
        }),
        height: '80px'
      }}
    >
      <Toolbar
        sx={{
          px: {lg: 5},
          p: 2
        }}
      >
        <Stack direction="row" width="100%" justifyContent={'space-between'}>
          <Logo />
          {currentUser && (
            <Stack alignItems={'center'} direction="row">
              <CuiAvatar
                name={currentUser?.fullName}
                value={currentUser?.initials}
                color={'white'}
              />
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  onOpenNav: PropTypes.func
}

export default observer(Header)
