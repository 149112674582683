import {useState} from 'react'
import User from '../../entities/User'
import {Menu, MenuItem, Typography} from '@mui/material'
import {useMainStore} from '../../contexts/Main'
import {Person} from 'src/entities/ApprovePerson'

interface SecretaryButtonProps {
  currentUserId?: number | undefined
  defaultText?: string
  users: User[] | Person[]
  onChange: (institutionId: number, userId: string) => void
  isEditable: boolean
}

const SecretaryButton: React.FC<SecretaryButtonProps> = ({
  currentUserId,
  users,
  defaultText,
  onChange,
  isEditable
}) => {
  const {currentInstitution} = useMainStore().institutionStore
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isEditable) setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUserChange = (userId: number) => {
    handleClose()
    if (onChange) {
      onChange(currentInstitution!.id, String(userId))
    }
  }

  return (
    <>
      <Typography
        variant="h5"
        style={{cursor: 'pointer', marginBottom: '8px'}}
        onClick={handleClick}
      >
        {currentUserId
          ? users?.find(u => u.id === currentUserId)?.fullName
          : defaultText}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {users?.map(user => (
          <MenuItem key={user.id} onClick={() => handleUserChange(user.id)}>
            {user.fullName}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SecretaryButton
