import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import CloseIcon from '@mui/icons-material/Close'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import CodeObject from 'src/entities/CodeObject'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'
import {LabelColor} from 'src/components/label'
import {useState} from 'react'
import {grey} from '@mui/material/colors'
import UploadButton from 'src/components/students/UploadButton'

const columns: GridColDef[] = [
  {
    field: 'indificationNo',
    headerName: 'ת.ז.',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'lastName',
    headerName: 'שם משפחה',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'firstName',
    headerName: 'שם פרטי',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'birthDate',
    headerName: 'תאריך לידה',
    type: 'date',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'learningShift',
    headerName: 'סדר נלמד',
    align: 'right',
    disableColumnMenu: true,
    valueGetter: (value: any) => value?.description
  },
  {
    field: 'learnLocation',
    headerName: 'מקום לימוד',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'scholarship',
    headerName: 'מלגה',
    width: 80,
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'street',
    width: 150,
    headerName: 'כתובת',
    align: 'right',
    disableColumnMenu: true,
    valueGetter: (_, row) => row.street + ' ' + row.houseNumber
  },
  {
    field: 'city',
    headerName: 'עיר',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'phone',
    headerName: 'טלפון',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'isOffset',
    headerName: 'מושהה בשל החלטת בג"ץ',
    align: 'right',
    disableColumnMenu: true,
    valueFormatter: value => (value ? 'כן' : 'לא')
  },
  {
    field: 'datotRateId',
    headerName: 'הגדרת זכאות במשרד הדתות',
    align: 'right',
    disableColumnMenu: true
  },
  {
    field: 'studentImportStatuses',
    headerName: 'סטטוס',
    width: 200,
    align: 'right',
    disableColumnMenu: true,
    renderCell: params =>
      (params.value as CodeObject[]).length > 0 ? (
        (params.value as CodeObject[]).map(c => (
          <LabelCol
            key={c.id}
            text={c.description}
            color={c.color as LabelColor}
            autoWidth
          />
        ))
      ) : (
        <LabelCol autoWidth text={'תקין'} color={'success' as LabelColor} />
      )
  }
]

interface ReportModalProps {
  isOpen: boolean
  onClose: () => void
}

const ReportModal = observer(({isOpen, onClose}: ReportModalProps) => {
  const {studentReports, lastReport, approveReport, isSaving} =
    useMainStore().studentsStore
  const {downloadFile} = useMainStore().azureStorageStore
  const [openConfirm, setOpenConfirm] = useState(false)

  const onApprove = () => {
    if (studentReports.some(s => s.statusesList && s.statusesList.length > 0))
      setOpenConfirm(true)
    else approveReport()
  }

  const onConfirm = () => {
    setOpenConfirm(false)
    approveReport()
  }

  return (
    <>
      <Dialog
        PaperProps={{sx: {height: '90%'}}}
        fullWidth
        maxWidth={'xl'}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle sx={{pb: 3}}>
          <Typography variant="h5">דו"ח של כל התלמידים במוסד</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 16,
            top: 16
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <DataGridPro
            pagination
            columns={columns}
            rows={studentReports}
            hideFooterRowCount
            hideFooterSelectedRowCount
            slotProps={{
              pagination: {
                labelRowsPerPage: 'שורות לעמוד:',
                labelDisplayedRows: ({from, to, count}) =>
                  `${from}-${to} מתוך ${count}`
              }
            }}
            sx={{
              '& .MuiDataGrid-scrollbar': {right: 'unset', left: '0'},
              '& .MuiDataGrid-virtualScroller': {direction: 'rtl'},
              '& .MuiTablePagination-root': {
                direction: 'rtl'
              },
              '& .MuiTablePagination-actions': {
                direction: 'ltr'
              },
              '& .MuiDataGrid-columnHeader': {background: '#F4F6F8'}
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ml: 1.5}}
            color="inherit"
            startIcon={<DownloadIcon sx={{ml: 1, mr: -0.5}} />}
            onClick={() =>
              downloadFile(
                lastReport?.document?.url ?? '',
                lastReport?.document?.name ?? ''
              )
            }
          >
            הורדת הדו”ח
          </Button>
          <UploadButton
            variant="outlined"
            color="inherit"
            text={'העלאת דו"ח חדש'}
          />
          <Button disabled={isSaving} variant="contained" onClick={onApprove}>
            אישור
            {isSaving && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  fontWeight: 'bold',
                  marginTop: -1.5,
                  marginLeft: -1.5
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirm && (
        <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
          <DialogTitle>
            <Typography variant="h5" align="center">
              {' '}
              נתונים חסרים / שגויים{' '}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{pt: '16px !important', pb: '0px !important'}}>
            <Typography variant="body1" color={grey[700]} align="center">
              {' '}
              האם אתה בטוח שברצונך להעלות את הקובץ
              <br /> הכולל נתונים חסרים / שגויים?
            </Typography>
          </DialogContent>
          <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
            <Button
              variant="contained"
              fullWidth
              sx={{m: 1}}
              color="inherit"
              onClick={() => setOpenConfirm(false)}
            >
              ביטול
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{m: 1}}
              onClick={onConfirm}
            >
              אישור
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
})
export default ReportModal
