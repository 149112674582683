import {makeAutoObservable} from 'mobx'
import {fTime} from 'src/utils/formatTime'

export default class Audit {
  id: number = 0

  auditor: string

  auditDate?: Date | null

  auditTime?: Date | null

  sector?: string

  yeshivaGuyCount?: string

  kollelManFullCount?: string

  kollelManShiftACount?: string

  kollelManShiftBCount?: string

  scholarship?: string

  sourceData?: string

  statusId: number

  note?: string

  generalImpression?: string

  institutionId: number = 0

  constructor(audit?: any) {
    this.id = audit?.Id ? Number(audit?.Id) : 0
    this.auditor = audit?.Auditor
    this.auditDate = audit?.AuditDate ? new Date(audit.AuditDate) : null
    this.auditTime = audit?.AuditTime ? fTime(audit.AuditTime.toString()) : null
    this.sector = audit?.Sector
    this.yeshivaGuyCount = audit?.YeshivaGuyCount
    this.kollelManFullCount = audit?.KollelManFullCount
    this.kollelManShiftACount = audit?.KollelManShiftACount
    this.kollelManShiftBCount = audit?.KollelManShiftBCount
    this.scholarship = audit?.Scholarship
    this.sourceData = audit?.SourceData
    this.statusId = audit?.StatusId || 1
    this.note = audit?.Note
    this.generalImpression = audit?.GeneralImpression
    this.institutionId = audit?.InstitutionId ? Number(audit?.InstitutionId) : 0

    makeAutoObservable(this)
  }
}
