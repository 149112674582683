import {makeAutoObservable} from 'mobx'
import Institution from './Institution'
import CodeObject from './CodeObject'

export default class Student {
  id: number

  firstName?: string

  lastName?: string

  indificationNo: string

  institutionName?: string

  passport?: string

  birthDate?: Date

  updatedAt: Date

  phone?: string

  shiftId?: number

  learningShift?: CodeObject

  learnLocation?: string

  typeId?: number

  street?: string

  houseNumber?: string

  city?: string

  scholarship?: number

  isOffset: boolean

  datotRateId?: number

  datotRate?: CodeObject

  institutionId?: number

  isDuplicate: boolean

  loadStatuses?: string

  sourceExcelReportId?: number

  statusId?: number

  institution?: Institution

  isMismatchDatot?: boolean

  isNeedPay?: boolean

  isReject?: boolean

  constructor(student: any, learningShifts: CodeObject[]) {
    this.id = student.Id
    this.firstName = student.FirstName
    this.lastName = student.LastName
    this.indificationNo = student.IndificationNo
    this.passport = student.Passport
    this.birthDate = new Date(student.BirthDate)
    this.phone = student.Phone
    this.shiftId = student.ShiftId
    this.learnLocation = student.LearnLocation
    this.typeId = student.TypeId
    this.houseNumber = student.HouseNumber
    this.scholarship = student.Scholarship
    this.isOffset = student.IsOffset
    this.datotRateId = student.DatotRateId
    this.updatedAt = student.UpdatedAt
    this.institutionId = student.InstitutionId
    this.isDuplicate = student.IsDuplicate
    this.loadStatuses = student.LoadStatuses
    this.sourceExcelReportId = student.SourceExcelReportId
    this.city = student.City
    this.street = student.Street
    this.statusId = student.StatusId
    this.learningShift = learningShifts?.find(l => l.id === student.ShiftId)

    this.institutionName = student.Institution
      ? student.Institution?.Name
      : undefined
    this.isMismatchDatot = student.IsMismatchDatot
    this.isNeedPay = student.IsNeedPay
    this.isReject = student.IsReject

    makeAutoObservable(this)
  }

  setReject = (isReject: boolean) => {
    this.isReject = isReject
  }

  setStatus = (statusId: number | undefined) => {
    this.statusId = statusId
  }
}

export enum StudentTab {
  All = 1,
  Approved,
  Duplicate,
  Rejected
}
