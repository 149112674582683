import {useLocation, Navigate} from 'react-router-dom'
import {Box, Typography} from '@mui/material'

import LoginButton from 'src/components/auth/LoginButton'
import {PATH_AUTH} from 'src/routes/paths'
import {useMainStore} from 'src/contexts/Main'
import {observer} from 'mobx-react-lite'

const LoginPage = observer(function LoginPage() {
  const location = useLocation()
  const {loginStore} = useMainStore()

  const {from} = (location.state as any) || {from: {pathname: '/'}}

  if (loginStore.isAccessDenied) {
    return <Navigate to={PATH_AUTH.accessDenied} />
  }
  if (loginStore.isAuth) {
    return <Navigate replace to={from} />
  }

  return (
    <Box
      textAlign="center"
      position="static"
      sx={{
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Typography component="h1" variant="h2">
        Welcome
      </Typography>
      <LoginButton />
    </Box>
  )
})

export default LoginPage
