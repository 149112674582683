import {Autocomplete, Box, Chip, TextField} from '@mui/material'
import {GridFilterInputValueProps} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export interface GridLabelFilterInputValueProps
  extends GridFilterInputValueProps {
  data: any[] | undefined
  multiple?: boolean
}

export const LabelInputComponent = observer(
  ({
    data,
    item,
    applyValue,
    multiple = true
  }: GridLabelFilterInputValueProps) => {
    return (
      <Box>
        <Autocomplete
          multiple={multiple}
          size="small"
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                <LabelCol
                  pointer
                  text={option.text || ''}
                  color={option.color || 'primary'}
                />
              </li>
            )
          }}
          getOptionLabel={option => option.text}
          limitTags={1}
          popupIcon={<ArrowDropDownIcon />}
          value={item.value}
          onChange={(_, newValue) => {
            applyValue({...item, value: newValue})
          }}
          isOptionEqualToValue={(option, v) =>
            option.id ? option.id === v.id : option === v
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                color={option.color}
                label={option.text}
                {...getTagProps({index})}
                key={option.value}
              />
            ))
          }
          options={data || []}
          renderInput={params => <TextField {...params} label="Value" />}
        />
      </Box>
    )
  }
)
