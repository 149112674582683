import {useState} from 'react'
import {Menu, MenuItem, TextField} from '@mui/material'

const booleanData = [
  {
    id: false,
    name: 'לא'
  },
  {
    id: true,
    name: 'כן'
  }
]

interface AssociationBelongsSelectProps {
  currentId?: boolean | undefined
  setEditedObject: React.Dispatch<React.SetStateAction<{[key: string]: any}>>
}

const AssociationBelongsSelect: React.FC<AssociationBelongsSelectProps> = ({
  currentId,
  setEditedObject
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (key: string, value: boolean) => {
    handleClose()
    setEditedObject(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  return (
    <>
      <TextField
        style={{cursor: 'pointer', marginBottom: '8px'}}
        onClick={handleClick}
        value={booleanData?.find(t => t.id === currentId)?.name}
      >
        {booleanData?.find(t => t.id === currentId)?.name}
      </TextField>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {booleanData?.map(t => (
          <MenuItem
            key={Number(t.id)}
            onClick={() => handleChange('isAssociationBelongTo', t.id)}
          >
            {t.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default AssociationBelongsSelect
