import {Button, CircularProgress} from '@mui/material'

import {ReactComponent as MicrosoftIcon} from 'src/images/MicrosoftLogo.svg'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'

const LoginButton = observer(function LoginButton() {
  const {loginStore} = useMainStore()

  return (
    <Button
      type="submit"
      variant="outlined"
      onClick={() => loginStore.login()}
      startIcon={<MicrosoftIcon />}
      endIcon={loginStore.isLoggingIn && <CircularProgress size={24} />}
      sx={{
        py: 1,
        px: 4,
        mt: 3,
        '& .MuiButton-startIcon': {
          padding: 1
        },
        '& .MuiButton-endIcon': {
          padding: 1
        }
      }}
    >
      Continue with Microsoft
    </Button>
  )
})

export default LoginButton
