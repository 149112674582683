import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import LabelCol from '../grid/columnTypes/LabelCol'
import {
  GridColDef,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import {format} from 'date-fns'
import {LabelColor} from '../label'
import {Paper, Stack, Typography} from '@mui/material'
import DataGridWrapper from '../grid/DataGridWrapperNew'
import {useState} from 'react'
import InstitutionalPaymentsToolBar from './InstitutionalPaymentsToolBar'
import {singleSelectFilterFunc} from 'src/components/ui/SingleSelectFilter'
import InstitutionNameCol from 'src/components/grid/columnTypes/InstitutionNameCol'

const InstitutionalPayments = observer(() => {
  const mainStore = useMainStore()

  const {currentUser} = mainStore.loginStore
  const {paymentsStatuses} = mainStore.paymentsStore

  const {institutionsStatuses, paymetByMonth} = mainStore.institutionStore

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const columns: GridColDef[] = [
    {
      field: 'paymentReportId',
      headerName: 'מזהה דו”ח',
      headerAlign: 'center',
      width: 60,
      type: 'number',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) => {
        if (row?.payments?.length > 0) {
          return row?.payments[0].paymentReport?.id
        }
        return null
      }
    },
    {
      field: 'id',
      headerName: 'מזהה מוסד',
      headerAlign: 'center',
      width: 60,
      sortable: true,
      filterable: true,
      type: 'number',
      align: 'center',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'שם מוסד',
      headerAlign: 'center',
      minWidth: 240,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      renderCell: params => (
        <InstitutionNameCol name={params.row.name} id={params.row.id} />
      )
    },
    {
      field: 'institutionHeadName',
      headerName: 'ראש מוסד',
      headerAlign: 'center',
      minWidth: 240,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1
    },

    {
      field: 'payDate',
      headerName: 'תאריך תשלום',
      minWidth: 150,
      type: 'date',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueGetter: (_, row) => {
        if (row?.payments?.length > 0) {
          const payDate = row?.payments[0].paymentReport?.payDate
          return payDate ? new Date(payDate) : null // Return a Date object or null
        }
        return null
      },
      valueFormatter: (_, row) => {
        const payDate =
          row?.payments?.length > 0
            ? row.payments[0].paymentReport?.payDate
            : undefined
        if (!payDate) return ''
        const date = new Date(payDate)
        return format(date, 'dd.MM.yy')
      }
    },
    {
      field: 'amount',
      headerName: 'סכום',
      headerAlign: 'center',
      type: 'number',
      width: 230,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        const amount = row?.payments?.length > 0 ? row?.payments[0].amount : 0

        return amount >= 0
          ? `${new Intl.NumberFormat('en-US').format(amount)} ש"ח`
          : ''
      }
    },
    {
      field: 'statusId',
      headerName: 'סטטוס מוסד',
      maxWidth: 110,
      sortable: true,
      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueFormatter: (_, row) => {
        const status = institutionsStatuses?.find(v => v.id === row.statusId)

        return status?.description
      },
      valueGetter: (_, row) => row?.statusId || '',
      renderCell: (params: any) => {
        const status = institutionsStatuses?.find(
          v => v.id === params.row.statusId
        )
        return (
          <LabelCol
            text={status?.description || ''}
            color={(status?.color || 'primary') as LabelColor}
          />
        )
      },
      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(institutionsStatuses)
    },
    {
      field: 'statusPaymentId',
      headerName: 'סטטוס תשלום',
      maxWidth: 110,
      sortable: true,
      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueFormatter: (_, row) => {
        const statusId =
          row?.payments !== undefined && row?.payments.length > 0
            ? row?.payments[0].statusId
            : 5
        const status = paymentsStatuses?.find(v => v.id === statusId)
        return status?.description
      },
      valueGetter: (_, row) => {
        if (row?.payments?.length > 0) {
          return row.payments[0].statusId
        }
        return 5
      },
      renderCell: (params: any) => {
        const statusId =
          params.row?.payments !== undefined && params.row?.payments.length > 0
            ? params.row?.payments[0].statusId
            : 5
        const status = paymentsStatuses?.find(v => v.id === statusId)
        return (
          <LabelCol
            text={status?.description || ''}
            color={(status?.color || 'primary') as LabelColor}
          />
        )
      },
      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(paymentsStatuses)
    }
  ]

  return (
    <>
      <Stack width={'100%'} height={'100%'} spacing={2}>
        <Typography variant="subtitle2">
          שלום {currentUser?.fullName}
        </Typography>
        <Paper sx={{width: '100%', p: 3}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={'h5'}> תשלומים למוסדות </Typography>

            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              alignItems="center"
            />
          </Stack>
        </Paper>

        <Paper
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxHeight: 'calc(100vh - 150px)'
          }}
        >
          <DataGridWrapper
            columns={columns}
            rows={paymetByMonth}
            hideFooter
            getRowHeight={() => 64}
            isCellEditable={(params: any) => {
              return params.row.statusId !== 2
            }}
            filterModel={filterModel}
            disableColumnMenu
            onFilterModelChange={(newValue: GridFilterModel) => {
              setFilterModel(newValue)
            }}
            slots={{
              toolbar: InstitutionalPaymentsToolBar
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl
              },
              baseSelect: {native: false, defaultValue: ''},
              toolbar: {
                setFilterButtonEl: setFilterButtonEl,
                setFilterModel: setFilterModel
              },
              filterPanel: {
                filterFormProps: {
                  logicOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small'
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-panelFooter': {
                    justifyContent: 'flex-start',
                    px: 2.5
                  },
                  '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                    color: (theme: any) => theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 171, 85, 0.08)'
                    }
                  },
                  '& .MuiDataGrid-filterForm': {p: 2},
                  '& .MuiDataGrid-filterFormLogicOperatorInput': {
                    mr: 1,
                    width: 75
                  },
                  '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                  '& .MuiDataGrid-filterFormOperatorInput': {
                    mr: 1,
                    width: 150
                  },
                  '& .MuiDataGrid-filterFormValueInput': {width: 280},
                  '& .MuiDataGrid-filterFormDeleteIcon': {
                    justifyContent: 'center'
                  }
                }
              }
            }}
          />
        </Paper>
      </Stack>
    </>
  )
})

export default InstitutionalPayments
