import {Logout} from '@mui/icons-material'
import {useRoutes} from 'react-router-dom'
import LoginPage from 'src/components/auth/LoginPage'
import ProtectedRoute from 'src/components/auth/ProtectedRoutes'
import Main from 'src/components/layout/Main'
import InstitutionsPage from 'src/components/Institutions/InstitutionsPage'
import InstitutionsView from 'src/components/Institutions/InstitutionsView'
import Payments from 'src/components/PaymentsPage/Payments'
import InstitutionalPayments from 'src/components/PaymentsPage/InstitutionalPayments'
import AccessDenied from 'src/components/auth/AccessDenied'
import DatotRates from 'src/components/DatotRates'

export default function Router() {
  return useRoutes([
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/accessDenied',
      element: <AccessDenied />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: <InstitutionsPage />
        },
        {
          path: '/institutions',
          element: <InstitutionsPage />
        },
        {
          path: '/institutions/:institutionId',
          element: <InstitutionsView />
        },
        {
          path: '/payments',
          element: <Payments />
        },
        {
          path: '/institutionalPayments',
          element: <InstitutionalPayments />
        },
        {
          path: '/datotRates',
          element: <DatotRates />
        }
      ]
    }
  ])
}
