import {format, getTime, formatDistanceToNow} from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy')
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p')
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date))
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  })
}

export function formatDate(date?: Date): string {
  if (!date) {
    return ''
  }
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()

  return `${day}/${month}/${year}`
}

export function fformatDate(date?: Date | null) {
  if (date) return format(date, 'yyyy-MM-dd')
}

export function fformatTime(date?: Date | null) {
  if (date) return format(date, 'HH:mm:ss')
}

export function fTime(date?: string) {
  if (date) {
    const [hours, minutes, seconds] = date.split(':')
    const time = new Date()
    time.setHours(
      parseInt(hours, 10),
      parseInt(minutes, 10),
      parseInt(seconds, 10)
    )
    return time
  }
}
