import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress
} from '@mui/material'
import {grey} from '@mui/material/colors'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'

interface UpdateRateDialogProps {
  onClose: () => void
  onSave: () => void
}

const UpdateRateDialog = observer(
  ({onClose, onSave}: UpdateRateDialogProps) => {
    const {isUpdateRateLoading} = useMainStore().codeTablesStore

    return (
      <>
        <Dialog open={true}>
          <DialogTitle>
            <Typography variant="h5" align="right">
              עדכון תעריף
            </Typography>
          </DialogTitle>
          <DialogContent sx={{pt: '16px !important', pb: '0px !important'}}>
            <Typography variant="body1" color={grey[700]} align="center">
              בפעולה זו מתעדכן התעריף בכל המערכת.
              <br /> האם אתה בטוח שברצונך לעדכן את התעריף?
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{justifyContent: 'left', alignItems: 'left', p: 2}}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{m: 1, width: '70px'}}
              color="inherit"
              onClick={() => onClose()}
              disabled={isUpdateRateLoading}
            >
              ביטול
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{m: 1, width: '70px'}}
              onClick={() => onSave()}
              disabled={isUpdateRateLoading}
            >
              אישור
              {isUpdateRateLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    fontWeight: 'bold',
                    marginTop: -1.5,
                    marginLeft: -1.5
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
)
export default UpdateRateDialog
