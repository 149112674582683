import {Stack} from '@mui/material'
import {
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'

const InstitutionsToolbar = observer(
  ({setFilterButtonEl}: GridToolbarProps) => {
    return (
      <>
        <Stack
          padding={1}
          paddingTop={2}
          direction="row"
          gap={1}
          justifyContent="flex-start"
        >
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton ref={setFilterButtonEl} />
        </Stack>
      </>
    )
  }
)
export default InstitutionsToolbar
