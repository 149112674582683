import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Stack
} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {observer} from 'mobx-react-lite'
import {grey} from '@mui/material/colors'
import {ReactComponent as DeleteIcon} from 'src/images/delete_black.svg'

interface DeletePaymentDialogProps {
  onClose: () => void
  onSave: () => void
}

const DeletePaymentDialog = observer(
  ({onClose, onSave}: DeletePaymentDialogProps) => {
    const {isDeletePayment} = useMainStore().paymentsStore

    return (
      <Dialog open={true}>
        <DialogTitle>
          <Stack direction="row" alignItems="center">
            <DeleteIcon />

            <Typography sx={{mr: '8px !important'}} variant="h5" align="center">
              מחיקת החלטת תשלום?
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{pt: '16px !important', pb: '0px !important'}}>
          <Typography variant="body1" color={grey[700]} align="center">
            האם אתה בטוח שברצונך למחוק החלטת תשלום מהדו”ח?
          </Typography>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'left', alignItems: 'end', p: 2}}>
          <Button
            disabled={isDeletePayment}
            onClick={() => onClose()}
            variant="contained"
            fullWidth
            sx={{ml: 1, width: '70px'}}
            color="inherit"
          >
            ביטול
          </Button>
          <Button
            variant="contained"
            onClick={() => onSave()}
            fullWidth
            disabled={isDeletePayment}
            sx={{width: '70px'}}
            color="error"
          >
            מחיקה
            {isDeletePayment && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  fontWeight: 'bold',
                  marginTop: -1.5,
                  marginLeft: -1.5
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
export default DeletePaymentDialog
