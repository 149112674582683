import React, {useState, ChangeEvent, useEffect} from 'react'
import {Typography, TextField, Button, Stack} from '@mui/material'
import {useMainStore} from '../../contexts/Main'
import {observer} from 'mobx-react-lite'

interface InstitutionNoteProps {
  text: string | null | undefined
  isEditMode: boolean
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const InstitutionNote = observer(
  ({text, isEditMode, setIsEditMode}: InstitutionNoteProps) => {
    const {currentInstitution, updateNote} = useMainStore().institutionStore
    const [currentText, setCurrentText] = useState<string>(text ?? '')

    useEffect(() => {
      setCurrentText(text || '')
    }, [text])

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentText(event.target.value)
    }

    const handleSave = async () => {
      try {
        if (currentText !== text)
          updateNote(currentInstitution!.id, currentText)
      } catch (error) {
        console.error('Error saving note:', error)
      }
      setIsEditMode(false)
    }

    const handleCancel = () => {
      setCurrentText(text || '')
      setIsEditMode(false)
    }

    if (isEditMode) {
      return (
        <Stack>
          <TextField
            multiline
            rows={6}
            value={currentText}
            onChange={handleTextChange}
            fullWidth
            placeholder="הכנס כאן את ההערות שלך... "
          />
          <Stack padding={1} direction="row" justifyContent={'flex-end'}>
            <Button
              disabled={
                currentText === '' ||
                currentText === null ||
                currentText === undefined
              }
              sx={{marginLeft: 1}}
              onClick={handleCancel}
              color="secondary"
              variant="outlined"
            >
              ביטול
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              שמירה
            </Button>
          </Stack>
        </Stack>
      )
    }

    return <Typography sx={{m: 2}}>{text}</Typography>
  }
)

export default InstitutionNote
