import {makeAutoObservable} from 'mobx'
import CodeObject from './CodeObject'

export default class Offset {
  id: number

  studentTypeId?: number

  studentType?: CodeObject

  studentCount?: number

  shiftBCount?: number

  shiftACount?: number

  offsetApril?: number

  offsetMay?: number

  offsetJone?: number

  offsetJoly?: number

  year?: number

  createdAt?: Date

  updatedAt?: Date

  constructor(offset: any, studentTypes?: CodeObject[]) {
    this.id = offset.Id

    this.studentTypeId = offset.StudentTypeId

    this.studentCount = offset.StudentCount

    this.shiftBCount = offset.ShiftBCount

    this.shiftACount = offset.ShiftACount

    this.offsetApril = offset.OffsetApril

    this.offsetMay = offset.OffsetMay

    this.offsetJone = offset.OffsetJone

    this.offsetJoly = offset.OffsetJoly

    this.year = offset.Year
    this.createdAt = new Date(offset.CreatedAt)

    this.updatedAt = new Date(offset.UpdatedAt)

    if (studentTypes)
      this.studentType = this.findStaudentType(
        studentTypes,
        offset.StudentTypeId
      )
    makeAutoObservable(this)
  }

  private findStaudentType(
    types: CodeObject[],
    typeId?: number
  ): CodeObject | undefined {
    if (typeId === undefined || typeId === null) {
      return undefined
    }
    return types.find(type => type.id === typeId) || undefined
  }
}
