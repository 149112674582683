import {useState, useCallback, useEffect} from 'react'
import {
  DataGridPro,
  DataGridProProps,
  gridClasses,
  GridCellModesModel,
  GridCellParams,
  GridCellModes
} from '@mui/x-data-grid-pro'
import CodeObject from 'src/entities/CodeObject'
import {Tabs, Tab, Stack, Typography, Box} from '@mui/material'
import Label from '../label/Label'
import {heIL} from '@mui/x-data-grid-pro/locales'

interface DataGridWrapperProps extends DataGridProProps {
  tabsData: CodeObject[]
  rows: any[]
}

const DataGridWrapper = ({tabsData, rows, ...props}: DataGridWrapperProps) => {
  const [filteredData, setFilteredData] = useState<any[]>(rows)
  const [activeTab, setActiveTab] = useState<number | null>(null)
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})

  const filterData = (rows: any[], tab: number | null) => {
    if (tab === null || tab === 0) return rows
    else return rows.filter(item => item.statusId === tab)
  }

  useEffect(() => {
    setFilteredData(filterData(rows, activeTab))
  }, [rows, activeTab])

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newActiveTab: number | null) => {
      setActiveTab(newActiveTab)
      setFilteredData(filterData(rows, newActiveTab))
    },
    [rows]
  )

  const tabs = [
    <Tab
      key="-1"
      label={
        <Stack direction="row">
          <Label color="info" ml={1}>
            {rows.length}
          </Label>
          <Typography>הכל</Typography>
        </Stack>
      }
    />,
    ...tabsData.map((tab: any) => (
      <Tab
        sx={{
          '&.MuiTab-root': {
            cursor: 'pointer',
            minWidth: 0, // Ensure that tabs don't have extra width
            padding: 0, // Adjust padding as needed
            margin: 1
          }
        }}
        key={tab.id}
        label={
          <Stack direction="row" spacing={0} alignItems="center">
            <Label color={tab.color || 'default'} ml={1}>
              {rows.filter(item => item.statusId === tab.id).length}
            </Label>
            <Typography>{tab.description}</Typography>
          </Stack>
        }
      />
    ))
  ]

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) return

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return
      }

      setCellModesModel(prevModel => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: {mode: GridCellModes.View}
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({...acc, [field]: {mode: GridCellModes.View}}),
              {}
            ),
            [params.field]: {mode: GridCellModes.Edit}
          }
        }
      })
    },
    []
  )

  const onCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel)
  }, [])

  return (
    <>
      <Box bgcolor={theme => theme.palette.grey[200]}>
        <Tabs
          sx={{
            backgroundColor: 'grey.200',
            '& .MuiTab-root': {
              marginLeft: 1,
              marginRight: 1
            },
            '& .MuiTab-root:last-child': {
              marginLeft: 3
            }
          }}
          value={activeTab}
          onChange={handleTabChange}
        >
          {tabs}
        </Tabs>
      </Box>
      <DataGridPro
        rows={filteredData}
        cellModesModel={cellModesModel}
        onCellModesModelChange={onCellModesModelChange}
        onCellClick={handleCellClick}
        {...props}
        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          '& .MuiDataGrid-main': {direction: 'rtl', overflowX: 'auto'},
          '& .MuiDataGrid-scrollbar': {right: 'unset', left: '0'},
          '& .MuiDataGrid-virtualScroller': {direction: 'rtl'},
          '& .MuiDataGrid-columnHeaderTitle': {textAlign: 'left'},
          '& .MuiDataGrid-colCellTitle': {textAlign: 'left'},
          [`&. MuiDataGrid-main`]: {width: '100%'},
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none'
            },
          [`& .${gridClasses.overlayWrapperInner}`]: {
            display: 'none'
          },
          [`& .${gridClasses.pinnedColumns}:first-of-type, .${gridClasses.pinnedColumns}:first-of-type`]:
            {
              boxShadow: 'none'
            },
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          direction: 'rtl'
        }}
      />
    </>
  )
}

export default DataGridWrapper
