import {Button, MenuItem, Select, Stack, Typography} from '@mui/material'
import {
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  useGridApiContext
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DownloadIcon from '@mui/icons-material/Download'
import {useMainStore} from 'src/contexts/Main'
import UploadButton from 'src/components/students/UploadButton'
import Month from 'src/entities/Month'

const StudentsToolBar = observer(
  ({setFilterButtonEl, setIsOpenReport, month, setMonth}: GridToolbarProps) => {
    const apiRef = useGridApiContext()
    const {getLastUpdate} = useMainStore().studentsStore
    const {relevantMonths} = useMainStore().institutionStore

    const handleChangeMonth = (event: any) => {
      const selectedMonth = JSON.parse(event.target.value) as Month
      setMonth(selectedMonth)
    }

    const downloadExcel = () =>
      apiRef.current.exportDataAsCsv({utf8WithBom: true, fileName: 'תלמידים'})
    return (
      <>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          sx={{p: 2}}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton ref={setFilterButtonEl} />
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography color={theme => theme.palette.grey[500]} sx={{ml: 1.5}}>
              {' '}
              תאריך עדכון הדו”ח: {getLastUpdate()}{' '}
            </Typography>
            <Button
              sx={{ml: 1.5}}
              variant="outlined"
              color="inherit"
              startIcon={<DownloadIcon sx={{ml: 1, mr: -1}} />}
              onClick={downloadExcel}
            >
              {' '}
              הורדה{' '}
            </Button>
            <UploadButton
              sx={{ml: 1.5}}
              variant="outlined"
              color="inherit"
              startIcon={<CloudUploadIcon sx={{ml: 1, mr: -1}} />}
              text={'העלאת דו"ח חדש'}
              setOpenUploadReport={() => setIsOpenReport(true)}
            />
            <Select
              sx={{
                width: '132px',
                height: '34px',
                fontSize: '14px',
                fontWeight: '700',
                backgroundColor:
                  'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))',
                alignSelf: 'end'
              }}
              value={month ? JSON.stringify(month) : ''}
              onChange={handleChangeMonth}
            >
              {relevantMonths?.map((m, i) => (
                <MenuItem
                  sx={{
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: '700'
                  }}
                  value={JSON.stringify(m)}
                  key={i}
                >
                  {`${m.name} ${m.year}`}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </>
    )
  }
)
export default StudentsToolBar
