import {Button, CircularProgress} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {ChangeEvent, useRef} from 'react'
import {getFileExtension} from 'src/utils/file'
import {UploadDocument} from 'src/entities/UploadDocument'

const UploadButton = observer(({text, setOpenUploadReport, ...props}: any) => {
  const {addDocument, currentInstitution, isLoadingUploadDoc} =
    useMainStore().institutionStore
  const {currentMonth} = useMainStore().studentsStore

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target
    if (files) {
      const file = files[0]
      await addDocument(
        {
          Name: file.name,
          CategoryId: 6,
          Extension: getFileExtension(file.name),
          Month: currentMonth.id,
          Year: currentMonth.year,
          InstitutionId: currentInstitution?.id
        } as UploadDocument,
        file,
        false
      )
      if (setOpenUploadReport) setOpenUploadReport()
    }
    event.target.value = ''
  }

  return (
    <>
      <Button
        disabled={isLoadingUploadDoc}
        onClick={handleButtonClick}
        startIcon={<CloudUploadIcon sx={{ml: 1, mr: -0.5}} />}
        {...props}
      >
        {text}
        {isLoadingUploadDoc && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              fontWeight: 'bold',
              marginTop: -1.5,
              marginLeft: -1.5
            }}
          />
        )}
      </Button>
      <input
        type="file"
        style={{display: 'none'}}
        accept=".xlsx, .xls"
        onChange={onUpload}
        ref={fileInputRef}
      />
    </>
  )
})

export default UploadButton
