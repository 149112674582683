import {ReactElement} from 'react'
import {LabelColor} from 'src/components/label'
import Label from 'src/components/label/Label'

export interface LabelProps {
  value: number
  text: string
  color: LabelColor
}

export interface LabelColProps {
  text: string
  color: LabelColor
  pointer?: boolean
  autoWidth?: boolean
  endIcon?: ReactElement
  startIcon?: ReactElement
}

export default function LabelCol({
  color,
  text,
  pointer,
  autoWidth,
  endIcon,
  startIcon
}: LabelColProps) {
  return (
    <Label
      color={color}
      sx={{
        cursor: pointer ? 'pointer' : undefined,
        mt: 1,
        mb: 1
      }}
      width={autoWidth ? 'auto' : '100%'}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {text}
    </Label>
  )
}
