import {useState} from 'react'
import {Menu, MenuItem, TextField} from '@mui/material'
import {useMainStore} from '../../contexts/Main'

interface IndificationTypeSelectProps {
  currentId?: number | undefined
  setEditedObject: React.Dispatch<React.SetStateAction<{[key: string]: any}>>
}

const IndificationTypeSelect: React.FC<IndificationTypeSelectProps> = ({
  currentId,
  setEditedObject
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {indificationTypes} = useMainStore().institutionStore

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (key: string, value: number) => {
    handleClose()
    setEditedObject(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  return (
    <>
      <TextField
        style={{cursor: 'pointer', marginBottom: '8px'}}
        onClick={handleClick}
        value={indificationTypes?.find(t => t.id === currentId)?.description}
      >
        {indificationTypes?.find(t => t.id === currentId)?.description}
      </TextField>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {indificationTypes?.map(t => (
          <MenuItem
            key={t.id}
            onClick={() => handleChange('indificationTypeID', t.id)}
          >
            {t.description}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default IndificationTypeSelect
