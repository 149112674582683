import {Button, Card, CardContent, Link, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useNavigate} from 'react-router-dom'
import {useMainStore} from 'src/contexts/Main'
import {ReactComponent as PasswordIcon} from 'src/images/ic_password.svg'

import {PATH_AUTH} from 'src/routes/paths'
import Header from '../layout/Header'

const AccessDenied = observer(function AccessDenied() {
  const {loginStore} = useMainStore()

  const navigate = useNavigate()

  const onTryAgainClick = async () => {
    loginStore.clearCache()
    loginStore.init()
    navigate(PATH_AUTH.login)
  }

  return (
    <>
      <Header />
      <Stack
        mt={15}
        mr={2}
        justifyContent="center"
        direction="row"
        alignItems="center"
        color="text.primary"
      >
        <Card sx={{width: 420, height: 400, boxShadow: 'none'}}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <PasswordIcon />
            <Stack alignItems="center" spacing={5}>
              <Typography component="h1" variant="h4">
                בעיה בזיהוי משתמש
              </Typography>
              <Typography sx={{textAlign: 'center'}} color="GrayText">
                המשתמש אינו קיים במערכת. אנא צור קשר עם מנהל המערכת:
                <Link
                  ml={1}
                  href={'mailto:Office@koh.org.il'}
                  underline="always"
                >
                  Office@koh.org.il
                </Link>
              </Typography>
            </Stack>
            <Button
              type="button"
              sx={{marginTop: '10%'}}
              variant="contained"
              fullWidth
              onClick={onTryAgainClick}
            >
              נסה שנית
            </Button>
          </CardContent>
        </Card>
      </Stack>
    </>
  )
})

export default AccessDenied
