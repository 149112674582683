import {makeAutoObservable} from 'mobx'

export default class Bank {
  id: number

  name: string

  bankBranches?: Branch[]

  label: string

  value: string

  constructor(bank: any) {
    this.id = Number(bank.id)
    this.name = bank.name
    this.label = bank.id + ' - ' + bank.name
    this.value = bank.name

    this.bankBranches = bank.bankBranches.length
      ? bank.bankBranches.map((b: any) => new Branch(b))
      : []
    makeAutoObservable(this)
  }
}

export class Branch {
  id: number

  label: string

  name: string

  constructor(branch: any) {
    this.id = Number(branch.code)
    // this.code = branch.code
    this.name = branch.name
    this.label = branch.code + ' - ' + branch.name
    makeAutoObservable(this)
  }
}
