import {TabProps, Typography,Stack, Tab} from '@mui/material'
import Label from 'src/components/label'

export interface CuiTabProps extends TabProps {
  text: string
  labelColor?: any
  labelText: number
}

export default function CuiTab({
  text,
  labelColor,
  labelText,
  ...props
}: CuiTabProps) {
  return (
    <Tab
      {...props}
      label={
        <Stack direction="row">
          <Label color={labelColor || 'default'} ml={1}>
            {labelText}
          </Label>
          <Typography>{text}</Typography>
        </Stack>
      }
    />
  )
}
