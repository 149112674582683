import {makeObservable, observable} from 'mobx'

export class Person {
  id: number

  fullName: string

  constructor(person: any) {
    this.id = person.Id

    this.fullName = person.Name

    makeObservable(this, {
      id: observable,
      fullName: observable
    })
  }
}

export default class ApprovePerson extends Person {
  constructor(approvePerson: any) {
    super(approvePerson)

    makeObservable(this)
  }
}
