import {makeAutoObservable} from 'mobx'
import CodeObject from 'src/entities/CodeObject'

export default class StudentReport {
  id: number

  firstName?: string

  lastName?: string

  indificationNo?: string

  birthDate?: Date

  excelStudentTypeId?: number

  street?: string

  houseNumber?: string

  city?: string

  phone?: string

  learningShiftId?: number

  learningShift?: CodeObject

  learnLocation?: string

  scholarship?: number

  isOffset: boolean

  datotRateId?: number

  institutionId?: number

  isApprove?: boolean

  loadStatuses?: string

  excelReportId?: number

  statusesList?: number[]

  studentImportStatuses?: CodeObject[]

  constructor(
    report: any,
    learningShifts: CodeObject[],
    studentImportStatuses: CodeObject[]
  ) {
    this.id = report.Id
    this.firstName = report.FirstName
    this.lastName = report.LastName
    this.indificationNo = report.IndificationNo
    this.birthDate = new Date(report.BirthDate)
    this.excelStudentTypeId = report.ExcelStudentTypeId
    this.phone = report.Phone
    this.learningShiftId = report.LearningShiftId
    this.learningShift = learningShifts?.find(
      l => l.id === report.LearningShiftId
    )
    this.learnLocation = report.LearnLocation
    this.houseNumber = report.HouseNumber
    this.scholarship = report.Scholarship
    this.isOffset = report.IsOffset
    this.datotRateId = report.DatotRateId
    this.institutionId = report.InstitutionId
    this.isApprove = report.isApprove
    this.loadStatuses = report.LoadStatuses
    this.excelReportId = report.ExcelReportId
    this.city = report.City
    this.street = report.Street
    this.statusesList = report.StatusesList
    this.studentImportStatuses = studentImportStatuses?.filter(s =>
      (report.StatusesList as number[]).includes(s.id)
    )

    makeAutoObservable(this)
  }
}
