import {Stack} from '@mui/material'
import PaymentsDecision from './PaymentDesicision'
import PaymentSummary from './PaymentSummary'
import {observer} from 'mobx-react-lite'
import {useMainStore} from '../../contexts/Main'
import PaymentsGrid from './PaymentsGrid'
import {useEffect} from 'react'

const PaymentsPage = observer(() => {
  const {payments, getPayments} = useMainStore().paymentsStore

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted) {
          getPayments()
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getPayments])

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        flexDirection: 'row-reverse',
        display: 'flex'
      }}
    >
      {/* StackA */}
      {payments && (
        <Stack
          sx={{
            minHeight: '100%',
            width: '60%',
            flexShrink: 0,
            flexGrow: 0
          }}
        >
          <PaymentsGrid />
        </Stack>
      )}

      <Stack
        sx={{
          height: '100%',
          flex: '1 1 0%', // Take remaining space
          display: 'flex',
          flexDirection: 'column' // Arrange StackD and StackE vertically
        }}
      >
        {/* StackC */}
        <Stack
          spacing={2}
          sx={{
            height: '50%', // 50% height of StackC
            display: 'flex',
            flexDirection: 'column' // Arrange StackD and StackE vertically
          }}
        >
          {/* StackD */}
          <Stack
            sx={{
              height: '50%', // 50% height of StackC
              display: 'flex'
            }}
          >
            {/* StackG */}
            <Stack
              sx={{
                width: '100%',
                minHeight: '100%',
                flexShrink: 0, // Do not shrink
                flexGrow: 0 // Do not grow
              }}
            >
              <PaymentSummary />
            </Stack>
          </Stack>

          {/* StackE */}
          <Stack
            sx={{
              height: '50%', // 50% height of StackC
              flexShrink: 0, // Do not shrink
              flexGrow: 0 // Do not grow
            }}
          >
            <PaymentsDecision />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
})

export default PaymentsPage
