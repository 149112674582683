import {MenuItem, Paper, Select, Stack, Typography} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import DataGridWrapper from 'src/components/grid/DataGridWrapperNew'
import {useEffect, useState} from 'react'
import RateCell from 'src/components/Datot/RateCell'
import {GridColDef} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import CuiLoading from 'src/components/custom/CuiLoading'
import UpdateRateDialog from 'src/components/Datot/UpdateRateDialog'
import Month from 'src/entities/Month'
import {defaultMonth} from 'src/utils/month'

const DatotRates = observer(() => {
  const mainStore = useMainStore()

  const {currentUser} = mainStore.loginStore
  const {datotRates, studentsTypes, updateDatotRate, getDatotRates} =
    mainStore.codeTablesStore
  const {relevantMonths} = mainStore.institutionStore
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [dialogParams, setDialogParams] = useState<any>(null)
  const [month, setMonth] = useState<Month | undefined>(defaultMonth)

  useEffect(() => {
    if (!month) return
    getDatotRates(month?.id, month?.year)
  }, [month, getDatotRates])

  const handleChangeMonth = (event: any) => {
    const selectedMonth = JSON.parse(event.target.value) as Month
    setMonth(selectedMonth)
  }

  const onSave = async () => {
    const {id, field, value, newValue, api, setEditMode} = dialogParams

    api.setEditCellValue({
      id: id,
      field: field,
      value: newValue
    })
    try {
      await updateDatotRate(id, Number(newValue))
      setOpenDialog(false)
      setEditMode(false)
      api.updateRows([{id: id, rate: newValue}])
    } catch (error) {
      api.setEditCellValue({
        id: id,
        field: field,
        value: value
      })
      api.updateRows([{id: id, rate: value}])
      setOpenDialog(false)
      setEditMode(false)
    }
  }

  const onClose = () => {
    const {value, setInputValue, setEditMode} = dialogParams
    setEditMode(false)
    setOpenDialog(false)
    setInputValue(value)
  }

  const columns: GridColDef[] = [
    {
      field: 'datotIds',
      headerName: 'מזהה',
      headerAlign: 'center',
      type: 'number',
      align: 'center',
      flex: 1
    },
    {
      field: 'datotStudentTypeId',
      headerName: 'תיאור',
      headerAlign: 'center',
      type: 'string',
      align: 'center',
      flex: 1,
      renderCell: (params: any) => {
        const type = studentsTypes?.find(
          (v: any) => v.Id === params.row.datotStudentTypeId
        ).Description
        return <>{type}</>
      }
    },
    {
      field: 'rate',
      headerName: 'תעריף',
      headerAlign: 'center',
      type: 'number',
      align: 'center',
      flex: 1,
      renderCell: params => (
        <RateCell
          setDialogParams={setDialogParams}
          setOpenDialog={setOpenDialog}
          {...params}
          isEditable={month?.id === new Date().getMonth() + 1}
        />
      )
    }
  ]

  const NoRowsOverlay = () => {
    return <div style={{textAlign: 'center', padding: '20px'}}>אין נתונים</div>
  }

  return (
    <>
      <Stack width={'100%'} height={'100%'} spacing={2}>
        <Typography variant="subtitle2">
          שלום {currentUser?.fullName}
        </Typography>
        <Paper sx={{width: '100%', p: 3}}>
          <Typography variant={'h5'}>תעריפי דתות</Typography>
        </Paper>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxHeight: 'calc(100vh - 150px)'
          }}
        >
          <Select
            sx={{
              width: '122px',
              height: '34px',
              minWidth: 'fit-content',
              fontSize: '14px',
              fontWeight: '700',
              backgroundColor:
                'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))',
              alignSelf: 'end'
            }}
            value={month ? JSON.stringify(month) : ''}
            onChange={handleChangeMonth}
          >
            {relevantMonths?.map((m, i) => (
              <MenuItem
                sx={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '700'
                }}
                value={JSON.stringify(m)}
                key={i}
              >
                {`${m.name} ${m.year}`}
              </MenuItem>
            ))}
          </Select>
          {datotRates && datotRates.length > 0 ? (
            <DataGridWrapper
              columns={columns}
              rows={datotRates}
              hideFooter
              getRowHeight={() => 64}
              disableColumnMenu
              slotProps={{
                baseSelect: {native: false, defaultValue: ''}
              }}
            />
          ) : datotRates?.length === 0 ? (
            <NoRowsOverlay />
          ) : (
            <CuiLoading />
          )}
        </Paper>
        {openDialog && <UpdateRateDialog onClose={onClose} onSave={onSave} />}
      </Stack>
    </>
  )
})

export default DatotRates
