import {
  Stack,
  TextField,
  InputAdornment,
  Card,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material'
import {ReactComponent as Refund} from 'src/images/ic_refund.svg'
import * as React from 'react'
import {DatePicker} from '@mui/x-date-pickers'
import {useMainStore} from '../../contexts/Main'
import {observer} from 'mobx-react-lite'
import CuiProgressButton from '../custom/CuiProgressButton'

const PaymentsDecision = observer(() => {
  const {createPayment, payments, updatePayment} = useMainStore().paymentsStore
  const {currentInstitution} = useMainStore().institutionStore
  const {isManager} = useMainStore().loginStore

  const now = new Date()
  const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1)

  const [selectedMonth, setSelectedMonth] = React.useState<Date | null>(
    currentMonth
  )
  const [amount, setAmount] = React.useState<number | undefined>(undefined)
  const [comment, setComment] = React.useState<string | null>()
  const [loading, setLoading] = React.useState(false)
  const [openRejectDialog, setRejectDialog] = React.useState(false)
  const [openOverrideDialog, setOverrideDialog] = React.useState(false)

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)
  }

  const onSaveDecision = async () => {
    if (amount === undefined || !selectedMonth || !currentInstitution?.id) {
      return
    }

    const existingPayment = payments?.find(
      payment =>
        payment.month === selectedMonth.getMonth() + 1 &&
        payment.year === selectedMonth.getFullYear() &&
        payment.institutionId === currentInstitution?.id
    )

    //there is payment with paid status
    if (
      existingPayment &&
      existingPayment.statusId !== 1 &&
      existingPayment.statusId !== 3
    ) {
      setRejectDialog(true)
      return
    }

    //there is payment but not paid status
    if (
      existingPayment &&
      (existingPayment.statusId === 1 || existingPayment.statusId === 3)
    ) {
      setOverrideDialog(true)
      return
    }
    setLoading(true)
    await createPayment({
      month: selectedMonth?.getMonth() + 1,
      year: selectedMonth?.getFullYear(),
      amount: amount,
      comment: comment,
      statusId: 1,
      institutionId: currentInstitution?.id
    })
    setComment('')
    setAmount(undefined)
    setSelectedMonth(new Date())
    setLoading(false)
  }

  const onOverrideDecision = async () => {
    setLoading(true)

    const existingPayment =
      selectedMonth &&
      payments?.find(
        payment =>
          payment.month === selectedMonth?.getMonth() + 1 &&
          payment.year === selectedMonth?.getFullYear() &&
          payment.institutionId === currentInstitution?.id
      )

    if (existingPayment) {
      existingPayment.amount = amount
      existingPayment.comment = comment !== null ? comment : undefined
      await updatePayment(existingPayment)
    }

    setComment('')
    setAmount(undefined)
    setSelectedMonth(new Date())
    setLoading(false)
    handleCloseDialog()
  }

  const handleCloseDialog = () => {
    setRejectDialog(false)
    setOverrideDialog(false)
  }

  React.useEffect(() => {}, [selectedMonth, amount, comment])

  return (
    <>
      <Card sx={{p: 2, m: 1.5, alignItems: 'center'}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1 // This makes the logo take up available height
          }}
        >
          <Refund />
        </Box>
        {isManager && (
          <Stack spacing={2}>
            <Stack direction="row" gap={10} justifyContent={'center'}>
              <TextField
                label="החלטת תשלום לחודש"
                value={amount}
                InputLabelProps={{
                  shrink: true,
                  sx: {textAlign: 'right', width: '100%'}
                }}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ש"ח</InputAdornment>
                  ),
                  type: 'number',
                  inputProps: {
                    step: '0.00',
                    min: '0'
                  }
                }}
                onChange={event => {
                  setAmount(Number(event.target.value))
                }}
              />

              <DatePicker
                format="MM/yyyy"
                openTo="month"
                views={['year', 'month']}
                label="בחירת חודש"
                value={selectedMonth}
                onChange={newValue => setSelectedMonth(newValue)}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    InputProps: {
                      placeholder: 'בחר חודש'
                    },
                    InputLabelProps: {
                      sx: {textAlign: 'right', width: '100%'}
                    }
                  }
                }}
              />
            </Stack>

            <Stack gap={2} direction={'row'} justifyContent={'space-between'}>
              <TextField
                value={comment}
                onChange={handleCommentChange}
                fullWidth
                placeholder="הכנס כאן את ההערות שלך... "
              />
              <CuiProgressButton
                disabled={!currentMonth || amount === undefined}
                onClick={onSaveDecision}
                loading={loading}
                color="primary"
                variant="contained"
              >
                שמירה
              </CuiProgressButton>
            </Stack>
          </Stack>
        )}
      </Card>
      <Dialog open={openRejectDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <Stack alignItems={'center'}>
            <Typography padding={2} variant="h4">
              {' '}
              כבר בוצע תשלום לחודש זה
            </Typography>
            <Typography> לא ניתן להוסיף החלטה זאת </Typography>
            <Typography> כיון שכבר בוצעה העברת תשלום.</Typography>
            <Typography> בדוק אם ברצונך להעביר תשלום על חודש אחר.</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
          >
            אישור
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOverrideDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <Stack alignItems={'center'}>
            <Typography padding={2} variant="h4">
              {' '}
              כבר בוצעה החלטה לחודש זה
            </Typography>
            <Typography> בחודש זה כבר הוחלט על תשלום.</Typography>
            <Typography> האם ברצונך לשנות את ההחלטה? </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleCloseDialog}
            variant="outlined"
            color="primary"
          >
            לא
          </Button>
          <Button
            fullWidth
            onClick={onOverrideDecision}
            variant="contained"
            color="primary"
          >
            כן
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default PaymentsDecision
