export default class Month {
  id: number

  name: string

  year: number

  constructor(month: any) {
    this.id = month.id
    this.name = month.name
    this.year = month.year
  }
}
