import {Autocomplete, Box, TextField, Chip} from '@mui/material'
import {GridFilterInputValueProps, GridFilterItem} from '@mui/x-data-grid-pro'
import {ReactComponent as DownIcon} from 'src/images/down.svg'
import {observer} from 'mobx-react-lite'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'

interface GridLabelFilterInputValueProps extends GridFilterInputValueProps {
  data: any[] | undefined
}

const LabelInputComponent = observer(
  ({data, item, applyValue}: GridLabelFilterInputValueProps) => {
    return (
      <Box>
        <Autocomplete
          multiple
          size="small"
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                <LabelCol
                  pointer
                  text={option.description || ''}
                  color={option.color || 'primary'}
                />
              </li>
            )
          }}
          getOptionLabel={option => option.description}
          limitTags={1}
          popupIcon={<DownIcon />}
          value={item.value}
          onChange={(_, newValue) => {
            applyValue({...item, value: newValue})
          }}
          isOptionEqualToValue={(option, v) =>
            option.id ? option.id === v.id : option === v
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                color={option.color}
                sx={{
                  direction: 'ltr'
                }}
                label={option.description}
                {...getTagProps({index})}
                key={option.value}
              />
            ))
          }
          options={data || []}
          renderInput={params => <TextField {...params} label="ערך" />}
        />
      </Box>
    )
  }
)
const isEmpty = (filterItem: GridFilterItem) => {
  if (
    !filterItem.field ||
    !filterItem.operator ||
    !filterItem.value ||
    !filterItem.value.length
  ) {
    return true
  }
  return false
}

const singleSelectFilterFunc = (data: any[] | undefined) => {
  return [
    {
      label: 'שווה',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return filterItem.value?.some((v: any) => {
            return v.id === params
          })
        }
      },
      InputComponent: (props: GridFilterInputValueProps) => (
        <LabelInputComponent data={data} {...props} />
      ),

      getValueAsString: (value: any[]) =>
        value.map(v => v.description).join(', ')
    },
    {
      label: 'לא שווה',
      value: 'is-not',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return !filterItem.value?.some((v: any) => {
            return v.id === params
          })
        }
      },
      InputComponent: (props: GridFilterInputValueProps) => (
        <LabelInputComponent data={data} {...props} />
      ),
      getValueAsString: (value: any[]) =>
        value.map(v => v.description).join(', ')
    }
  ]
}

export {singleSelectFilterFunc}
