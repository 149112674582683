import {BlobServiceClient} from '@azure/storage-blob'
import {makeAutoObservable} from 'mobx'
import config from 'src/config'
import {downloadBlob} from 'src/utils/download'
import {MainStore} from 'src/store/MainStore'
import {getMediaType} from 'src/utils/file'

export class AzureStorageStore {
  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
  }

  private generateSas = async () => {
    const res = await this.owner.loginStore.fetchWithUser(
      `${config.apiUrl}/documents/GenerateSAS`
    )
    return res.text()
  }

  private isExpired = (sas: string) => {
    if (!sas) return true

    const params = new URLSearchParams(sas)
    const sasExpiration = new Date(params.get('se') || '')
    return (
      !sasExpiration ||
      Math.round(
        (new Date(sasExpiration).getTime() - new Date().getTime()) / 60000
      ) < 10
    )
  }

  private getSas = async () => {
    let sas = localStorage.getItem('sas') || ''
    if (this.isExpired(sas)) {
      sas = await this.generateSas()
      localStorage.setItem('sas', sas)
    }
    return sas
  }

  private getClient = async () => {
    const sas = await this.getSas()
    if (sas) {
      const service = new BlobServiceClient(
        `${config.azureBlobStorage.storageName}?${sas}`
      )
      const {containerName} = config.azureBlobStorage

      return service.getContainerClient(containerName)
    }
  }

  getBlobUrl = async (url: string) => {
    const sas = await this.getSas()
    const blobUrl = `${config.azureBlobStorage.storageName}${
      config.azureBlobStorage.containerName
    }/${encodeURIComponent(url)}?${sas}`
    return blobUrl
  }

  getBlob = async (filePath: string) => {
    const containerClient = await this.getClient()
    if (containerClient) {
      try {
        const blockBlobClient = containerClient.getBlockBlobClient(filePath)
        if (await blockBlobClient.exists()) {
          const downloadBlockBlobResponse = await blockBlobClient.download()

          const blob = await downloadBlockBlobResponse.blobBody
          return blob
        } else return null
      } catch {
        return null
      }
    }
  }

  downloadFile = async (filePath: string, fileName: string) => {
    var blob = await this.getBlob(filePath)
    if (blob) {
      downloadBlob(blob, fileName)
    }
  }

  uploadFile = async (
    blob: Blob,
    type: string,
    filePath: string,
    maxRetries = 3
  ) => {
    let retries = 0

    const uploadWithRetry = async (): Promise<boolean> => {
      try {
        await this.getSas()

        const containerClient = await this.getClient()

        if (containerClient === undefined) return false

        const blockBlobClient = containerClient.getBlockBlobClient(filePath)

        console.log('save file: before sending file ' + filePath)

        await blockBlobClient.uploadData(blob, {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 8,
          blobHTTPHeaders: {
            blobContentType: getMediaType(type)
          }
        })

        console.log('save file: send file success ' + filePath)

        return true
      } catch (error) {
        console.error(error)

        if (this.isSasExpiredError(error) && retries < maxRetries) {
          retries++
          console.log(`Retrying upload (attempt ${retries})...`)
          return await uploadWithRetry()
        }

        return false
      }
    }

    return uploadWithRetry()
  }

  isSasExpiredError = (error: any) => {
    return (
      error.code === 'SasTokenExpired' ||
      error.message.includes('SAS token has expired')
    )
  }
}
