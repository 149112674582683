// @mui
// useTheme
import {Stack, Box} from '@mui/material'
// config
import {NAV} from 'src/config'
// utils
// components
import {NavSectionMini, NavSectionData} from 'src/components/nav-section'
// import SearchIcon from 'src/images/search.svg?react'
// import SearchSelectedIcon from 'src/images/searchSelected.svg?react'
import {ReactComponent as Book} from 'src/images/book.svg'
import {ReactComponent as Dolar} from 'src/images/dolar.svg'
import {ReactComponent as North} from 'src/images/north.svg'
import {ReactComponent as File} from 'src/images/ic_file.svg'

//import NorthEast from '@mui/icons-material/NorthEast'
// interface IconProps {
//   isSelected?: boolean
//   IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
// }

// const Icon = ({ isSelected, IconComponent }: IconProps) => {
//   const theme = useTheme()
//   const iconColor = isSelected
//     ? theme.palette.primary.light
//     : theme.palette.grey[600]

//   return <IconComponent fill={iconColor} />
// }

const MainNavMini = () => {
  const navConfig = () => {
    const navConfig: NavSectionData[] = [
      {
        subheader: '',
        items: [
          {
            title: 'רשימת מוסדות',
            path: 'institutions',
            icon: <Book />,
            iconSelected: <Book />
          }
        ]
      },
      {
        subheader: '',
        items: [
          {
            title: 'דו”חות תשלום',
            path: 'payments',
            icon: <North />,
            iconSelected: <North />
          }
        ]
      },
      {
        subheader: '',
        items: [
          {
            title: 'תשלומי מוסדות',
            path: 'institutionalPayments',
            icon: <Dolar />,
            iconSelected: <Dolar />
          }
        ]
      },
      {
        subheader: '',
        items: [
          {
            title: 'תעריפי דתות',
            path: 'datotRates',
            icon: <File />,
            iconSelected: <File />
          }
        ]
      }
    ]
    return navConfig
  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        /////
        width: {lg: NAV.W_DASHBOARD_MINI}
      }}
    >
      <Stack
        py={5}
        sx={{
          pb: 2,
          height: 1,
          width: NAV.W_DASHBOARD_MINI,
          borderRight: theme => `dashed 10px ${theme.palette.grey}`,
          background: theme =>
            `var(--12-background-dark-01-default,${theme.palette.common.white})`
        }}
      >
        <Stack justifyContent="space-between" height="100%" alignItems="start">
          <Stack alignItems="start">
            <NavSectionMini data={navConfig()} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default MainNavMini
