import {Button, MenuItem, Select, Stack, Typography} from '@mui/material'
import {grey} from '@mui/material/colors'
import {useState} from 'react'
import {ReactComponent as CheckIcon} from 'src/images/check.svg'
import ReportModal from 'src/components/students/ReportModal'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import Month from 'src/entities/Month'

const CheckReport = () => {
  const [isOpen, setIsOpen] = useState(false)

  const {relevantMonths} = useMainStore().institutionStore
  const {currentMonth, setCurrentMonth} = useMainStore().studentsStore

  const handleChangeMonth = (event: any) => {
    const selectedMonth = JSON.parse(event.target.value) as Month
    setCurrentMonth(selectedMonth)
  }

  return (
    <Stack direction="column">
      <Select
        sx={{
          width: '122px',
          height: '34px',
          minWidth: 'fit-content',
          fontSize: '14px',
          fontWeight: '700',
          backgroundColor:
            'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))',
          alignSelf: 'end'
        }}
        value={currentMonth ? JSON.stringify(currentMonth) : ''}
        onChange={handleChangeMonth}
      >
        {relevantMonths?.map((m, i) => (
          <MenuItem
            sx={{
              color: 'black',
              fontSize: '14px',
              fontWeight: '700'
            }}
            value={JSON.stringify(m)}
            key={i}
          >
            {`${m.name} ${m.year}`}
          </MenuItem>
        ))}
      </Select>
      <Stack alignItems={'center'} spacing={2}>
        <CheckIcon />
        <Typography variant="h5">אישור דו”ח תלמידים</Typography>
        <Typography variant="body1" align="center" color={grey[700]}>
          יש לבדוק ולאשר את קובץ דו”ח כל התלמידים במוסד.
        </Typography>
        <Button
          sx={{width: '25%', alignItems: 'center'}}
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          בדיקת הקובץ
        </Button>
        <ReportModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </Stack>
    </Stack>
  )
}
export default observer(CheckReport)
