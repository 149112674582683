import {makeAutoObservable} from 'mobx'

export default class User {
  id: number

  lastName: string

  firstName: string

  email?: string

  createdAt?: Date

  updatedAt?: Date

  roleId?: number

  constructor(user: any) {
    this.id = user.Id
    this.lastName = user.LastName
    this.firstName = user.FirstName
    this.email = user.Email
    this.createdAt = new Date(user.CreatedAt)
    this.updatedAt = new Date(user.UpdatedAt)
    this.roleId = user.RoleId

    makeAutoObservable(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get initials() {
    return ((this.firstName[0] || '') + (this.lastName[0] || '')).toUpperCase()
  }
}
