import {makeAutoObservable} from 'mobx'
import CodeObject from './CodeObject'
import Institution from './Institution'

export class PaymentReport {
  id: number

  payDate: Date

  constructor(paymentReport: any) {
    this.id = paymentReport.Id
    this.payDate = paymentReport.PayDate
  }
}
export default class Payment {
  id: number

  date?: Date

  amount?: number

  month?: number

  year?: number

  comment?: string

  statusId?: number

  paymentReportId: number

  institutionId: number

  createdAt?: Date

  updatedAt?: Date

  status?: CodeObject

  institution?: Institution

  paymentReport?: PaymentReport

  constructor(payment: any, statuses?: CodeObject[]) {
    this.id = payment.Id
    this.amount = payment.Amount
    this.month = payment.Month
    this.year = payment.Year
    this.comment = payment.Comment
    this.statusId = payment.StatusId
    this.paymentReportId = payment.PaymentReportId
    this.institutionId = payment.InstitutionId
    this.institution = payment.Institution
      ? new Institution(payment.Institution)
      : undefined
    this.createdAt = new Date(payment.CreatedAt)
    this.updatedAt = new Date(payment.UpdatedAt) || undefined
    this.paymentReport = payment.PaymentReport
      ? new PaymentReport(payment.PaymentReport)
      : undefined
    if (statuses) this.status = this.findStatus(statuses, payment.StatusId)
    makeAutoObservable(this)
  }

  private findStatus(
    statuses: CodeObject[],
    statusId?: number
  ): CodeObject | undefined {
    if (statusId === undefined || statusId === null) {
      return undefined
    }
    return statuses.find(status => status.id === statusId)
  }

  setStatus = (statusId: number | undefined) => {
    this.statusId = statusId
  }

  get isEditable() {
    return this.statusId === 1 || this.statusId === 3
  }
}
