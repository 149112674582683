import {makeAutoObservable} from 'mobx'
import Document from 'src/entities/Document'
export default class Report {
  id: number

  institutionId: number

  statusId: number

  month?: number

  year?: number

  loadStatuses?: string

  document?: Document

  constructor(report: any) {
    this.id = report.Id
    this.institutionId = report.InstitutionId
    this.statusId = report.StatusId
    this.loadStatuses = report.LoadStatuses
    this.month = report.Month
    this.year = report.Year
    this.document = new Document(report.Document)

    makeAutoObservable(this)
  }

  setStatusId = (statusId: number) => {
    this.statusId = statusId
  }
}
