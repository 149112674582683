import {makeAutoObservable} from 'mobx'

export default class Contact {
  id: number

  firstName?: string

  lastName?: string

  indificationTypeID?: number

  indificationNo?: string

  passport?: string

  title?: string

  phone?: string

  cellPhone?: string

  email?: string

  street?: string

  houseNumber?: string

  zipCode?: string

  city?: string

  createdAt?: Date

  updatedAt?: Date

  constructor(contact: any) {
    this.id = contact.Id
    this.lastName = contact.LastName
    this.firstName = contact.FirstName
    this.email = contact.Email
    this.createdAt = new Date(contact.CreatedAt)
    this.city = contact.City
    this.zipCode = contact.ZipCode
    this.indificationTypeID = contact.IndificationTypeID
    this.indificationNo = contact.IndificationNo
    this.passport = contact.Passport
    this.title = contact.Title
    this.phone = contact.Phone
    this.cellPhone = contact.CellPhone
    this.email = contact.Email
    this.street = contact.Street
    this.houseNumber = contact.HouseNumber
    this.updatedAt = new Date(contact.UpdatedAt)
    makeAutoObservable(this)
  }
}
