import {Select, SelectChangeEvent, MenuItem} from '@mui/material'
import {useState} from 'react'

export interface RenderEditCellSecretariesProps {
  value: any
  secretaries: any[]
  onChange: (event: SelectChangeEvent) => void
}
const RenderEditCellSecretaries = ({
  value,
  secretaries,
  onChange
}: RenderEditCellSecretariesProps) => {
  const [open, setOpen] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event)
    setOpen(false)
  }
  return (
    <Select
      fullWidth
      sx={{borderRadius: 0, lineHeight: 'inherit', height: 'inherit'}}
      open={open}
      value={value}
      onChange={handleChange}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      {secretaries?.map((s, i) => (
        <MenuItem value={s.id} key={i}>
          {s.fullName}
        </MenuItem>
      ))}
    </Select>
  )
}

export default RenderEditCellSecretaries
