import {Select, SelectChangeEvent, MenuItem} from '@mui/material'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'

export interface RenderEditLabelCellProps {
  value: any
  values: any[]
  onChange: (event: SelectChangeEvent) => void
}

const RenderEditLabelCell = ({
  value,
  values,
  onChange
}: RenderEditLabelCellProps) => {
  return (
    <Select
      fullWidth
      sx={{
        borderRadius: 0
      }}
      value={value}
      onChange={e => {
        onChange(e)
      }}
      renderValue={val => {
        const lv = values?.find(v => v.id === val)
        return (
          <LabelCol
            pointer
            text={lv?.description || ''}
            color={lv?.color || 'primary'}
          />
        )
      }}
    >
      {values?.map((v, i) => (
        <MenuItem value={v.id} key={i}>
          <LabelCol pointer text={v.description} color={v.color} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default RenderEditLabelCell
