import {makeAutoObservable} from 'mobx'

export default class Document {
  id: number

  institutionId: number

  name: string

  url?: string

  categoryId: number

  extension?: string

  statusId?: number

  month?: number

  year?: number

  constructor(document: any) {
    this.id = document.Id
    this.institutionId = document.InstitutionId
    this.name = document.Name
    this.url = document.Url
    this.categoryId = document.CategoryId
    this.extension = document.Extension
    this.statusId = document.StatusId
    this.month = document.Month
    this.year = document.Year

    makeAutoObservable(this)
  }
}
