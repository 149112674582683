export function getFileExtension(fileName: string) {
  return fileName.substring(fileName.lastIndexOf('.') + 1)
}

export function getMediaType(type: string) {
  type = type.toLowerCase()
  switch (type) {
    case 'doc':
      return 'application/msword'
    case 'docs':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'pdf':
      return 'application/pdf'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    default:
      return ''
  }
}
