import {Button, Stack} from '@mui/material'
import {
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  useGridApiContext
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {DatePicker} from '@mui/x-date-pickers'
import {useEffect} from 'react'
import DownloadIcon from '@mui/icons-material/Download'

const InstitutionalPaymentsToolBar = observer(
  ({setFilterButtonEl}: GridToolbarProps) => {
    const {selectedPaymetMonth, setSelectedPaymetMonth} =
      useMainStore().institutionStore
    const apiRef = useGridApiContext()

    const downloadExcel = () => {
      const fileName = selectedPaymetMonth
        ? 'תשלומים למוסדות_' +
          Number(selectedPaymetMonth.getMonth() + 1) +
          '_' +
          selectedPaymetMonth.getFullYear()
        : 'תשלומים למוסדות'
      apiRef.current.exportDataAsCsv({utf8WithBom: true, fileName: fileName})
    }

    useEffect(() => {
      let isMounted = true
      const fetchData = async () => {
        try {
          if (isMounted) {
            setSelectedPaymetMonth(new Date())
          }
        } catch (error) {
          if (isMounted) {
            console.error('Error fetching data:', error)
          }
        }
      }
      fetchData()
      return () => {
        isMounted = false
      }
    }, [setSelectedPaymetMonth])

    return (
      <>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton ref={setFilterButtonEl} />
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <DatePicker
              format="MM/yyyy"
              openTo="month"
              views={['year', 'month']}
              label="בחירת חודש"
              value={selectedPaymetMonth}
              onChange={newValue => setSelectedPaymetMonth(newValue)}
              slotProps={{
                textField: {
                  variant: 'standard',
                  InputProps: {
                    placeholder: 'בחר חודש'
                  },
                  InputLabelProps: {
                    sx: {textAlign: 'right', width: '100%'}
                  }
                }
              }}
            />

            <Button
              sx={{ml: 1.5}}
              variant="outlined"
              color="inherit"
              startIcon={<DownloadIcon sx={{ml: 1, mr: -1}} />}
              onClick={downloadExcel}
            >
              {' '}
              הורדה{' '}
            </Button>
          </Stack>
        </Stack>
      </>
    )
  }
)
export default InstitutionalPaymentsToolBar
