import {makeAutoObservable} from 'mobx'

export default class Association {
  id: number

  name?: string

  associationId?: number

  street?: string

  houseNumber?: string

  zipCode?: string

  city?: string

  phone?: string

  accountOwner?: string

  bank?: string

  bankId?: number

  branch?: string

  branchId?: number

  accountNumber?: string

  createdAt?: Date

  updatedAt?: Date

  clearShiftReference?: string

  constructor(association: any) {
    this.id = association.Id
    this.name = association.Name
    this.associationId = association.AssociationId
    this.street = association.Street
    this.houseNumber = association.HouseNumber
    this.zipCode = association.ZipCode
    this.city = association.City
    this.phone = association.Phone
    this.accountOwner = association.AccountOwner
    this.bank = association.Bank
    this.bankId = association.BankId
    this.branch = association.Branch
    this.branchId = association.BranchId
    this.accountNumber = association.AccountNumber
    this.createdAt = new Date(association.CreatedAt)
    this.updatedAt = new Date(association.UpdatedAt)
    this.clearShiftReference = association.ClearShiftReference

    makeAutoObservable(this)
  }
}
