import {Autocomplete, TextField} from '@mui/material'
import {observer} from 'mobx-react-lite'

export interface AutocompleteOption {
  id: string
  label: string
}

export interface SearchProps {
  options: any[]
  name: string
  label: string
  getValue: (o: any) => any
  loading?: boolean
  onChange?: (value: any | null) => void
  error?: boolean
  errorMessage?: string
}

const CustomAutocomplete = observer(
  ({options, label, onChange, value, error, errorMessage, ...props}: any) => {
    return (
      <Autocomplete
        options={options}
        value={value}
        onChange={(event: any, newValue: any) => {
          onChange(newValue)
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            helperText={error ? errorMessage : ''}
            error={error}
            sx={{
              '& .MuiFormHelperText-root': {
                color: 'error.main'
              },
              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: 'error.main'
                }
            }}
          />
        )}
        {...props}
      />
    )
  }
)

export default CustomAutocomplete
