import {observer} from 'mobx-react-lite'
import {generatePath, Link} from 'react-router-dom'
import {PATH_KOT} from 'src/routes/paths'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'
import {useState} from 'react'

interface InstitutionNameColProps {
  name: string
  id: string
  fontSize?: number
}

const InstitutionNameCol = observer(
  ({name, id, fontSize}: InstitutionNameColProps) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
      <Link
        to={generatePath(PATH_KOT.institution, {institutionId: id})}
        onMouseEnter={() => {
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
        }}
        style={{textDecoration: 'none'}}
      >
        <CuiOverflowTypography
          color={isHovered ? 'primary.light' : 'black'}
          fontSize={fontSize}
          fontWeight="bold"
        >
          {name}
        </CuiOverflowTypography>
      </Link>
    )
  }
)

export default InstitutionNameCol
