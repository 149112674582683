import {ReactNode} from 'react'
import Button from '@mui/material/Button'
import Dialog, {DialogProps} from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {ColorSchema} from 'src/theme/palette'
import {Typography} from '@mui/material'

export interface CuiAlertDialogProps extends DialogProps {
  open: boolean
  close: () => void
  title?: string
  body?: ReactNode
  okButtonText: string
  disableBackdropClick?: boolean
  okClick: () => void
  color?: ColorSchema
  showCancel?: boolean
  showMessage?: boolean
  onClickMessage?: () => void
  isFullWidth?: boolean
  disableOkButton?: boolean
}

export default function CuiAlertDialog({
  open,
  close,
  title,
  body,
  okButtonText,
  disableBackdropClick,
  okClick,
  showCancel,
  disableOkButton,
  ...props
}: CuiAlertDialogProps) {
  const onClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && disableBackdropClick)) {
      close()
    }
  }

  const onOkClick = () => {
    okClick()
    close()
  }

  return (
    <Dialog open={open} onClose={onClose} {...props} maxWidth="xs" fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
        {' '}
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{pt: '16px !important', pb: '0px !important'}}>
        {body}
      </DialogContent>
      <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
        {showCancel && (
          <Button
            color="inherit"
            variant="outlined"
            onClick={close}
            sx={{m: 1}}
            fullWidth
          >
            ביטול
          </Button>
        )}

        <Button
          fullWidth
          variant="contained"
          onClick={onOkClick}
          disabled={disableOkButton}
          sx={{m: 1}}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
