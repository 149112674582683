import {Paper, IconButton} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {GridFilterModel, GridLogicOperator} from '@mui/x-data-grid-pro'
import {useEffect, useState, useMemo, useCallback} from 'react'
import {ReactComponent as DownloadIcon} from 'src/images/download.svg'
import {ReactComponent as ExcelIcon} from 'src/images/excel.svg'
import {ReactComponent as PdfIcon} from 'src/images/pdf.svg'
import Attachment from 'src/entities/Attachment'
import {ReactComponent as ImageIcon} from 'src/images/image.svg'
import config from 'src/config'
import {LabelColor} from 'src/components/label'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'
import {monthNames} from 'src/utils/month'
import DocsToolBar from 'src/components/grid/DocsToolBar'
import DataGridWrapper from 'src/components/grid/DataGridWrapperNew'
import RenderEditLabelCell from 'src/components/grid/RenderEditCell'
import {format} from 'date-fns'
import UploadDocDialog from 'src/components/docs/UploadDocDialog'
import {UploadDocument} from 'src/entities/UploadDocument'
import {observer} from 'mobx-react-lite'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {singleSelectFilterFunc} from 'src/components/ui/SingleSelectFilter'

interface DocsTabProps {
  institutionId: number
}

const DocsTab = observer(({institutionId}: DocsTabProps) => {
  const mainStore = useMainStore()
  const {downloadFile} = mainStore.azureStorageStore
  const {fetchWithUser} = mainStore.loginStore
  const [docs, setDocs] = useState<Attachment[]>([])
  const {getInstitutionsDocs, docsCategories, docsStatuses, addDocument} =
    mainStore.institutionStore
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  )

  const onClose = () => setOpenDialog(false)

  const updateDoc = useCallback(
    async (doc: Attachment) => {
      try {
        const response = await fetchWithUser(`${config.apiUrl}/Documents`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(doc)
        })
        const data = await response.json()
        if (data) {
          setDocs(prevRows =>
            prevRows.map(row => (row.Id === doc.Id ? doc : row))
          )
        } else {
          throw new Error('failed to update doc  details')
        }
      } catch (error) {}
    },
    [fetchWithUser]
  )

  const onSave = async (doc: any) => {
    const newDoc = await addDocument(
      {
        Name: doc.name,
        CategoryId: doc.categoryId,
        Extension: doc.extension,
        Month: doc.month || new Date().getMonth() + 1,
        Year: doc.year || new Date().getFullYear(),
        InstitutionId: institutionId
      } as UploadDocument,
      doc.file
    )

    if (!newDoc) {
      setSnackbarSeverity('error')
      setSnackbarMessage('העלאת הקובץ נכשלה, נסה שנית.')
      setSnackbarOpen(true)
      onClose()
    } else {
      newDoc.id = newDoc.Id
      setDocs(prev => [...prev, newDoc])
      setSnackbarSeverity('success')
      setSnackbarMessage('הקובץ עלה בהצלחה')
      setSnackbarOpen(true)
      onClose()
    }
  }

  const columns: any[] = useMemo(() => {
    return [
      {
        field: 'Name',
        headerName: 'שם המסמך',
        minWidth: 350,
        headerAlign: 'center',
        type: 'string',
        disableColumnMenu: true,
        align: 'center',
        flex: 1,
        renderCell: (params: any) => (
          <div style={{display: 'flex', alignItems: 'center'}}>
            {params.row.Extension === 'xlsx' ? (
              <ExcelIcon />
            ) : params.row.Extension === 'pdf' ? (
              <PdfIcon />
            ) : (
              <ImageIcon />
            )}
            {params.value}
          </div>
        )
      },
      {
        field: 'CategoryId',
        headerName: 'קטגוריה',
        headerAlign: 'center',
        minWidth: 240,
        sortable: true,
        filterable: true,
        type: 'string',
        align: 'center',
        flex: 1,
        valueGetter: (params: any) =>
          params ? docsCategories?.find(c => c.id === params).description : ''
      },
      {
        field: 'Extension',
        headerName: 'סוג',
        headerAlign: 'center',
        minWidth: 120,
        type: 'string',
        align: 'center',
        flex: 1
      },
      {
        field: 'Month',
        headerName: 'חודש',
        minWidth: 50,
        type: 'number',
        align: 'center',
        flex: 1,
        headerAlign: 'center',
        valueGetter: (params: any) => {
          return monthNames[params - 1] || ''
        }
      },
      {
        field: 'Year',
        headerName: 'שנה',
        minWidth: 50,
        type: 'number',
        align: 'center',
        flex: 1,
        headerAlign: 'center',
        valueGetter: (params: any) => {
          return params?.toString() || ''
        }
      },
      {
        field: 'CreatedAt',
        headerName: 'תאריך העלאה',
        minWidth: 150,
        type: 'date',
        align: 'center',
        flex: 1,
        headerAlign: 'center',
        valueGetter: (params: any) => {
          return new Date(params)
        },
        valueFormatter: (params: any) => {
          const date = new Date(params)
          return format(date, 'dd.MM.yy')
        }
      },
      {
        field: 'StatusId',
        headerName: 'סטטוס',
        minWidth: 110,
        sortable: true,
        filterable: true,
        align: 'center',
        flex: 1,
        headerAlign: 'center',
        valueFormatter: (params: any) => {
          const status = docsStatuses?.find(v => v.id === params)
          return status?.description || ''
        },
        renderCell: (params: any) => {
          const status = docsStatuses?.find(v => v.id === params.row.StatusId)
          return (
            <LabelCol
              text={status?.description || ''}
              color={(status?.color || 'primary') as LabelColor}
            />
          )
        },
        editable: true,
        renderEditCell: (params: any) => {
          return (
            <RenderEditLabelCell
              value={params.row.StatusId}
              values={docsStatuses || []}
              onChange={e => {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: e.target.value
                })
                updateDoc({...params.row, StatusId: e.target.value})
              }}
            />
          )
        },
        type: 'singleSelect',
        filterOperators: singleSelectFilterFunc(docsStatuses)
      },
      {
        sortable: false,
        filterable: false,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params: any) => (
          <IconButton
            onClick={() => downloadFile(params.row.Url, params.row.Name)}
            color="primary"
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
        )
      }
    ]
  }, [docsCategories, updateDoc, downloadFile, docsStatuses])

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        const docs = await getInstitutionsDocs(institutionId)
        if (isMounted) {
          setDocs(docs)
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [institutionId, getInstitutionsDocs])

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  return (
    <>
      <Paper sx={{width: '100%', height: '80vh', maxHeight: '100%', p: 2}}>
        <DataGridWrapper
          columns={columns}
          rows={docs || []}
          hideFooter
          // getRowHeight={() => 64}
          filterModel={filterModel}
          disableColumnMenu
          onFilterModelChange={(newValue: GridFilterModel) => {
            setFilterModel(newValue)
          }}
          slots={{
            toolbar: DocsToolBar
          }}
          slotProps={{
            panel: {
              anchorEl: filterButtonEl
            },
            baseSelect: {native: false, defaultValue: ''},
            toolbar: {
              setFilterButtonEl: setFilterButtonEl,
              setFilterModel: setFilterModel,
              addDoc: () => setOpenDialog(true)
            },
            filterPanel: {
              filterFormProps: {
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small'
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: {mt: 'auto'}
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: {mt: 'auto'}
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small'
                  }
                }
              },
              sx: {
                '& .MuiDataGrid-panelFooter': {
                  justifyContent: 'flex-start',
                  px: 2.5
                },
                '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                  color: (theme: any) => theme.palette.primary.main,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 171, 85, 0.08)'
                  }
                },
                '& .MuiDataGrid-filterForm': {p: 2},
                '& .MuiDataGrid-filterFormLogicOperatorInput': {
                  mr: 1,
                  width: 75
                },
                '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                '& .MuiDataGrid-filterFormOperatorInput': {
                  mr: 1,
                  width: 150
                },
                '& .MuiDataGrid-filterFormValueInput': {width: 280},
                '& .MuiDataGrid-filterFormDeleteIcon': {
                  justifyContent: 'center'
                }
              }
            }
          }}
        />
        {openDialog && (
          <UploadDocDialog
            categories={docsCategories || []}
            onClose={onClose}
            onSave={onSave}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </>
  )
})

export default DocsTab
