import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config, {systemObjects} from 'src/config'
import CodeObject, {CodeObjectType} from 'src/entities/CodeObject'
import DatotRate from 'src/entities/DatotRate'
import {MainStore} from 'src/store/MainStore'

export class CodeTablesStore {
  datotRates?: DatotRate[]

  studentsTypes?: any[]

  isUpdateRateLoading?: boolean

  codeObjects: Record<CodeObjectType, CodeObject[]>

  constructor(readonly owner: MainStore) {
    this.codeObjects = {} as Record<CodeObjectType, CodeObject[]>

    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isAuth => {
        if (isAuth) {
          this.getAll()
          this.getDatotRates(
            new Date().getMonth() + 1,
            new Date().getFullYear()
          )
          this.getStudentTypes()
        }
      }
    )
  }

  getDatotRates = (month: number, year: number) => {
    const params: any = {
      month,
      year
    }

    const url = new URL(`${config.apiUrl}/CodeTables/DatotRates`)

    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )
    this.owner.loginStore
      .fetchWithUser(url.toString())
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.datotRates = data.map((dr: any) => new DatotRate(dr))
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getAll = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/CodeTables/all`)
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            Object.keys(data).forEach((key: string) => {
              const enumKey = key as keyof typeof CodeObjectType

              this.codeObjects[CodeObjectType[enumKey]] = data[key].map(
                (item: any) => new CodeObject(item)
              )
            })
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getStudentTypes = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.datotStudentTypes}`
      )
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.studentsTypes = data
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  setIsUpdateRateLoading = (isLoading: boolean) => {
    this.isUpdateRateLoading = isLoading
  }

  updateDatotRate = async (studentTypeId: number, rate: number) => {
    this.setIsUpdateRateLoading(true)
    const params: any = {
      studentTypeId: studentTypeId,
      rate: rate
    }

    const url = new URL(`${config.apiUrl}/CodeTables/DatotRate`)

    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )

    await this.owner.loginStore
      .fetchWithUser(url.toString(), {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res: any) => {
        return res.json()
      })
      .then(() => {
        runInAction(() => {
          const rateToUpdate = this.datotRates?.find(
            dr => dr.datotStudentTypeId === studentTypeId
          )
          if (rateToUpdate) rateToUpdate.rate = rate
        })
        this.setIsUpdateRateLoading(false)
      })
      .catch(() => {
        this.setIsUpdateRateLoading(false)
        this.owner.setErrorMessage('השינויים לא נשמרו, נסה שוב.')
        throw new Error()
      })
  }
}
