export function downloadUrl(
  url: string,
  fileName: string,
  isMultiple?: boolean
) {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  if (isMultiple) link.target = '_blank'
  link.click()
}

export function downloadBlob(blob: Blob, fileName: string) {
  const href = window.URL.createObjectURL(blob)
  downloadUrl(href, fileName)
  window.URL.revokeObjectURL(href)
}
