import {ChangeEvent, useRef} from 'react'
import {FormControl, Stack, Typography, Button} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

export interface FileProps {
  onUpload: (file: File) => void
  onDelete: () => void
  isMissingFile: boolean
}
const File = ({onUpload, onDelete, isMissingFile}: FileProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target
    if (files) {
      onUpload(files[0])
    }
    event.target.value = ''
  }

  // const onDeleteFile = () => onDelete()
  // To do: remove
  console.log(onDelete)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  return (
    <FormControl sx={{width: '100%'}}>
      <Stack sx={{pt: 2, pb: 2}}>
        <Button
          variant="outlined"
          sx={{width: '100%'}}
          onClick={handleButtonClick}
        >
          <CloudUploadIcon />
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Heebo',
              fontWeight: 'bold',
              pr: '10px'
            }}
          >
            בחר מסמך
          </Typography>
        </Button>
        {isMissingFile && (
          <Typography sx={{color: '#d32f2f', fontSize: '0.75rem'}}>
            חובה להעלות קובץ
          </Typography>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{display: 'none'}}
          onChange={onUploadFile}
        />
      </Stack>
    </FormControl>
  )
}

export default File
