import React, {useState} from 'react'
import {Chip, MenuItem, Menu} from '@mui/material'
import CodeObject from '../../entities/CodeObject'
import {useMainStore} from '../../contexts/Main'
import {LabelColor} from '../label/types'

interface StatusesProps {
  currentStatusId: number
  statuses: CodeObject[]
}

const StatusLabel: React.FC<StatusesProps> = ({currentStatusId, statuses}) => {
  const {currentInstitution, updateInstitutionsStatus} =
    useMainStore().institutionStore

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleStatusChange = (statusId: number) => {
    handleClose()
    updateInstitutionsStatus(currentInstitution!.id, String(statusId))
  }

  return (
    <>
      {currentStatusId && (
        <>
          <Chip
            color={
              (statuses.find(status => status.id === currentStatusId)
                ?.color as LabelColor) || 'primary'
            }
            label={
              statuses.find(status => status.id === currentStatusId)
                ?.description
            }
            onClick={handleClick}
            style={{cursor: 'pointer'}}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
          >
            {statuses.map(status => (
              <MenuItem
                key={status.id}
                onClick={() => handleStatusChange(status.id)}
              >
                <Chip
                  color={(status.color as LabelColor) || 'default'}
                  label={status.description}
                  style={{cursor: 'pointer'}}
                />
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  )
}

export default StatusLabel
