// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import {AuthContextProvider} from 'src/contexts/Auth'
import DirectionWrapper from 'src/components/ui/DirectionWrapper'
import MainStoreProvider from 'src/contexts/Main'
import {LicenseInfo} from '@mui/x-license'
import config from './config'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey(config.MUIDataGridLicenseKey)

export default function App() {
  return (
    <MainStoreProvider>
      <DirectionWrapper>
        <AuthContextProvider>
          <MotionLazyContainer>
            <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router />
              </LocalizationProvider>
            </ThemeProvider>
          </MotionLazyContainer>
        </AuthContextProvider>
      </DirectionWrapper>
    </MainStoreProvider>
  )
}
