import {useEffect} from 'react'
import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import UploadReport from 'src/components/students/UploadReport'
import CheckReport from 'src/components/students/CheckReport'
import FailedReport from 'src/components/students/FailedReport'
import StudentsList from 'src/components/students/StudentsList'

const StudentsTab = observer(() => {
  const {students, lastReport, getStudentsData} = useMainStore().studentsStore

  useEffect(() => {
    getStudentsData()
  }, [getStudentsData])

  return (
    <Stack>
      {students.length ? (
        <StudentsList />
      ) : !lastReport ? (
        <UploadReport />
      ) : lastReport.statusId === 2 ? (
        <CheckReport />
      ) : [3, 5].includes(lastReport?.statusId) ? (
        <FailedReport
          url={lastReport.document?.url ?? ''}
          fileName={lastReport.document?.name ?? ''}
        />
      ) : (
        <></>
      )}
    </Stack>
  )
})
export default StudentsTab
