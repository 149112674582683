import {Paper, Stack, Tab, Tabs, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {useParams, useSearchParams} from 'react-router-dom'
import {useCallback, useEffect, useState} from 'react'
import CuiLoading from '../custom/CuiLoading'
import TabPanel from '../custom/CuiTabPanel'
import InstitutionsDetails from './InstitutionsDetails'
import StatusLabel from './InstitutionsStatusButton'
import SecretaryButton from './InstitutionsSecretaryButton'

import DocsTab from 'src/components/docs/DocsTab'
import StudentsTab from 'src/components/students/StudentsTab'
import {ViewType} from 'src/entities/TabType'
import PaymentsPage from '../institutions-payments/PaymentsPage'
import AuditDetailsTab from './AuditDetailsTab'
import {formatDate} from 'src/utils/formatTime'

const InstitutionsView = observer(() => {
  const {
    institutions,
    setCurrentInstitution,
    currentInstitution,
    getInstitution,
    institutionsStatuses,
    setBranches,
    updateInstitutionsAssignee,
    updateApprovePerson
  } = useMainStore().institutionStore
  const {secretaries, approvePersons} = useMainStore().usersStore
  const {currentUser, isManager} = useMainStore().loginStore
  const {institutionId} = useParams()
  const [selectedTab, setSelectedTab] = useState(ViewType.InstitutionsDetails)
  const [searchParams, setSearchParams] = useSearchParams()

  const view = searchParams.get('view')

  const noSecretary = 'אין מזכירה משויכת'
  const noApprovePerson = 'אין מאשר'

  const isInstitutionsDetailsView =
    selectedTab && selectedTab === ViewType.InstitutionsDetails

  useEffect(() => {
    if (isInstitutionsDetailsView && !view) {
      setSearchParams({view: ViewType[ViewType.InstitutionsDetails]})
    }
  }, [isInstitutionsDetailsView, view, setSearchParams])

  const onChooseView = useCallback(
    (view: ViewType) => {
      if (view) {
        setSelectedTab(view)
        setSearchParams({view: ViewType[view]})
      }
    },
    [setSearchParams]
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChooseView(newValue as ViewType)
  }

  useEffect(() => {
    if (institutionId) {
      const foundInstitution = institutions?.find(
        i => i.id === Number(institutionId)
      )
      setCurrentInstitution(foundInstitution)
      setBranches(foundInstitution?.association?.bankId || undefined)
    }
  }, [
    institutionId,
    getInstitution,
    institutions,
    setCurrentInstitution,
    setBranches
  ])

  useEffect(() => {
    if (view) {
      onChooseView(ViewType[view as keyof typeof ViewType] as ViewType)
    }
  }, [view, onChooseView])

  return (
    <>
      <Stack width={'100%'} sx={{mx: 5}} spacing={2}>
        <Typography variant="subtitle2">
          שלום {currentUser?.fullName}
        </Typography>
        {!currentInstitution ? (
          <CuiLoading />
        ) : (
          <>
            <Paper sx={{width: '100%', p: 2}}>
              <Stack
                alignItems={'center'}
                sx={{ml: 15}}
                direction="row"
                justifyContent={'space-between'}
              >
                <Stack direction="column">
                  <Typography color={theme => theme.palette.grey[500]}>
                    שם המוסד
                  </Typography>
                  <Typography variant="h5">
                    {currentInstitution?.name}
                  </Typography>
                </Stack>

                <Stack direction="column">
                  <Typography color={theme => theme.palette.grey[500]}>
                    סוג המוסד
                  </Typography>
                  <Typography variant="h5">
                    {currentInstitution?.type?.description || ''}
                  </Typography>
                </Stack>

                <Stack direction="column">
                  <Typography color={theme => theme.palette.grey[500]}>
                    מזהה מוסד
                  </Typography>
                  <Typography variant="h5">{currentInstitution?.id}</Typography>
                </Stack>

                <Stack direction="column">
                  <Typography color={theme => theme.palette.grey[500]}>
                    תאריך הצטרפות{' '}
                  </Typography>
                  <Typography variant="h5">
                    {currentInstitution?.createdAt
                      ? formatDate(currentInstitution!.createdAt)
                      : ''}
                  </Typography>
                </Stack>

                <Stack direction="column">
                  <Typography color={theme => theme.palette.grey[500]}>
                    מזכירה אחראית{' '}
                  </Typography>
                  <SecretaryButton
                    key="1"
                    currentUserId={currentInstitution?.assignUserId}
                    users={secretaries!}
                    onChange={updateInstitutionsAssignee}
                    defaultText={noSecretary}
                    isEditable={true}
                  />
                </Stack>

                <Stack direction="column">
                  <Typography color={theme => theme.palette.grey[500]}>
                    מאשר{' '}
                  </Typography>
                  <SecretaryButton
                    key="2"
                    currentUserId={currentInstitution?.approvePersonId}
                    users={approvePersons!}
                    onChange={updateApprovePerson}
                    defaultText={noApprovePerson}
                    isEditable={isManager}
                  />
                </Stack>
                <StatusLabel
                  currentStatusId={currentInstitution?.statusId || -1}
                  statuses={institutionsStatuses!}
                />
              </Stack>
            </Paper>

            <Stack sx={{width: '100%'}}>
              <Tabs
                sx={{borderRight: 0, marginLeft: 'auto'}} // Align tabs to the right
                value={selectedTab}
                onChange={handleTabChange}
              >
                <Tab
                  value={ViewType.InstitutionsDetails}
                  key={ViewType.InstitutionsDetails}
                  label="נתונים"
                />
                <Tab
                  value={ViewType.Students}
                  key={ViewType.Students}
                  label="תלמידים"
                />
                <Tab
                  value={ViewType.Payments}
                  key={ViewType.Payments}
                  sx={{p: 2}}
                  label="תשלומים"
                />
                <Tab
                  value={ViewType.Docs}
                  key={ViewType.Docs}
                  sx={{p: 2}}
                  label="מסמכים"
                />
                <Tab
                  value={ViewType.Audit}
                  key={ViewType.Audit}
                  sx={{p: 2}}
                  label="ביקורת"
                />
              </Tabs>
              <TabPanel
                value={selectedTab}
                index={ViewType.InstitutionsDetails}
              >
                <InstitutionsDetails />
              </TabPanel>
              <TabPanel value={selectedTab} index={ViewType.Students}>
                <StudentsTab />
              </TabPanel>
              <TabPanel value={selectedTab} index={ViewType.Payments}>
                <PaymentsPage />
              </TabPanel>
              <TabPanel value={selectedTab} index={ViewType.Docs}>
                <DocsTab institutionId={currentInstitution.id} />
              </TabPanel>
              <TabPanel value={selectedTab} index={ViewType.Audit}>
                <AuditDetailsTab />
              </TabPanel>
            </Stack>
          </>
        )}
      </Stack>
    </>
  )
})

export default InstitutionsView
