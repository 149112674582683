export default class DatotRate {
  id?: number

  datotStudentTypeId?: number

  datotIds?: string

  rate?: number

  constructor(datotRate: any) {
    this.id = datotRate.DatotStudentTypeId
    this.datotStudentTypeId = datotRate.DatotStudentTypeId
    this.datotIds = datotRate.DatotIds
    this.rate = datotRate.Rate
  }
}
