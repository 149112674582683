import {observer} from 'mobx-react-lite'
import Autocomplete from 'src/components/ui/AutoComplete'
import Bank from 'src/entities/Bank'
import {useMainStore} from 'src/contexts/Main'

interface AutoCompleteBankPrpos {
  currentId: number
  setEditedObject: React.Dispatch<React.SetStateAction<{[key: string]: any}>>
  fieldName: string
  options: any[] | undefined
  error?: boolean
  errorMessage?: string
}

const AutoCompleteBank = observer(
  ({
    currentId,
    setEditedObject,
    fieldName,
    options,
    error,
    errorMessage
  }: AutoCompleteBankPrpos) => {
    const {setBranches} = useMainStore().institutionStore

    const handleChange = (value: any) => {
      if (fieldName === 'bankId') {
        setBranches(value?.id)
      }
      setEditedObject(prevState => ({
        ...prevState,
        [fieldName]: value?.id,
        ...(fieldName === 'bankId'
          ? {bank: value?.name, branch: undefined, branchId: undefined}
          : {}),
        ...(fieldName === 'branchId' ? {branch: value?.name} : {})
      }))
    }

    return (
      <Autocomplete
        dir="rtl"
        options={options}
        onChange={handleChange}
        value={options?.find(b => Number(b.id) === currentId)}
        getOptionLabel={(option: Bank) => option.label}
        errorMessage={error ? errorMessage : ''}
        error={error}
      />
    )
  }
)

export default AutoCompleteBank
