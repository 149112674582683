import {observer} from 'mobx-react-lite'
import Autocomplete from 'src/components/ui/AutoComplete'
import Bank from 'src/entities/Bank'

interface AutoCompleteBankPrpos {
  value: number
  setEditedObject: React.Dispatch<React.SetStateAction<{[key: string]: any}>>
  fieldName: string
  options: any[] | undefined
  error?: boolean
  errorMessage?: string
}

const AutoCompleteCity = observer(
  ({
    value,
    setEditedObject,
    fieldName,
    options,
    error,
    errorMessage
  }: AutoCompleteBankPrpos) => {
    const handleChange = (value: any) => {
      setEditedObject(prevState => ({
        ...prevState,
        [fieldName]: value?.label || null
      }))
    }

    return (
      <Autocomplete
        dir="rtl"
        options={options}
        onChange={handleChange}
        value={options?.find(b => b.label === value)}
        getOptionLabel={(option: Bank) => option.label}
        errorMessage={error ? errorMessage : ''}
        error={error}
      />
    )
  }
)

export default AutoCompleteCity
