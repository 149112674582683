import {makeAutoObservable} from 'mobx'

export default class CodeObject {
  id: number

  name: string

  description: string

  color?: string

  constructor(codeObject: any) {
    this.id = codeObject.Id
    this.name = codeObject.Name
    this.description = codeObject.Description
    this.color = codeObject.Color
    makeAutoObservable(this)
  }
}

export enum CodeObjectType {
  DocumentCategories = 1,
  DocumentStatuses = 2,
  InstitutionsTypes = 3,
  InstitutionsStatuses = 4,
  LearningShifts = 5,
  PaymentStatuses = 6,
  StudentReportStatuses = 7,
  StudentTypes = 8,
  IndificationTypes = 9,
  DatotRates = 10,
  StudentImportStatuses = 11,
  StudentStatuses = 12,
  DatotStudentTypes = 13,
  AuditStatuses = 14
}
