import {User} from '@microsoft/microsoft-graph-types'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {Logger, UserAgentApplication} from 'msal'
import config from 'src/config'
import DBUser from 'src/entities/User'
import {MainStore} from 'src/store/MainStore'
import popupWindow from 'src/utils/popupWindow'

export class LoginStore {
  readonly msal: UserAgentApplication

  isMsalAuth: boolean = false

  isAuth: boolean = false

  roles: string[] = []

  isLoggingIn: boolean = false

  isFetchingUser: boolean = false

  user: User | null = null

  currentUser: DBUser | null = null

  loginError: string = ''

  tokenError: string = ''

  shouldLogout: boolean = false

  loginMessage: string = ''

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
    this.msal = new UserAgentApplication({
      auth: {
        redirectUri: config.AADRedirectUrl,
        postLogoutRedirectUri: window.location.href,
        ...config.AADConfig
      },
      cache: {cacheLocation: 'localStorage'},
      system: {
        logger: new Logger((level: any, message: any) => {
          if (!config.isProduction) console.log('[msal]', level, message)
        })
      }
    })

    reaction(
      () => this.isMsalAuth,
      async isMsalAuth => {
        if (isMsalAuth && (await this.getAccessToken(this.scope.graph))) {
          if (!this.currentUser) this.getCurrentUser()
        }
      }
    )

    reaction(
      () => this.isAuth,
      async isAuth => {
        if (isAuth) this.getUserProfile()
      }
    )
  }

  get isAccessDenied() {
    return !this.isAuth && this.loginMessage !== ''
  }

  init() {
    runInAction(() => {
      this.loginMessage = ''

      this.isMsalAuth = this.msal && !!this.msal.getAccount()

      this.isFetchingUser = this.msal && !!this.msal.getAccount()
    })
  }

  get isManager() {
    return this.currentUser?.roleId === 2
  }

  async login() {
    runInAction(() => {
      this.isLoggingIn = true
      this.loginError = ''
      this.loginMessage = ''
    })
    try {
      // Login via popup
      await this.msal.loginPopup({
        scopes: this.scope.graph,
        prompt: 'select_account',
        state: this.requestState
      })

      runInAction(() => {
        this.isAuth = true
        this.isLoggingIn = false
        this.shouldLogout = false
        this.isMsalAuth = true
        this.isFetchingUser = true
        this.isFetchingUser = true
        this.loginMessage = ''
      })
    } catch (err: any) {
      runInAction(() => {
        this.isLoggingIn = false
        this.isAuth = false
        this.roles = []
        this.isMsalAuth = false
        this.loginError = err.errorMessage
      })
    }
  }

  logout = () => {
    if (window.opener && window.opener !== window) return this.msal.logout()
    const logoutWin = popupWindow('/logout', '', 500, 600)
    if (logoutWin)
      logoutWin.onbeforeunload = () =>
        runInAction(() => {
          this.isAuth = false
          this.roles = []
          this.isMsalAuth = false
          this.user = null
          this.currentUser = null
          this.loginMessage = ''
        })
  }

  fetchWithUser = async (
    endpoint: RequestInfo,
    options: RequestInit = {},
    scopes: string[] = this.scope.api
  ): Promise<Response> => {
    const accessToken = await this.getAccessToken(scopes)
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`
    }
    const mergedOpts = {...options, headers}
    return fetch(endpoint, mergedOpts).then(response => {
      if (!response.ok) {
        return Promise.reject(response)
      }
      return response
    })
  }

  getUserProfile = async () => {
    runInAction(() => (this.isFetchingUser = true))
    const res = await this.fetchWithUser(
      'https://graph.microsoft.com/v1.0/me',
      {},
      this.scope.graph
    )
    const {error, ...user} = await res.json()
    if (error) runInAction(() => (this.isFetchingUser = false))
    runInAction(() => {
      this.user = user
      this.isFetchingUser = false
    })
  }

  getCurrentUser = () => {
    this.fetchWithUser(config.apiUrl + '/Users/current')
      .then(response => response !== null && response.json())
      .then((data: DBUser) => {
        runInAction(() => {
          this.isAuth = true
          this.currentUser = new DBUser(data)
          this.isLoggingIn = false
          this.shouldLogout = false
          this.isMsalAuth = true
          this.isFetchingUser = true
          this.isFetchingUser = true
          this.loginMessage = ''
        })
      })
      .catch(() => {
        runInAction(() => {
          this.shouldLogout = true
          this.isAuth = false
          this.roles = []
          this.isMsalAuth = false
          this.isLoggingIn = false
          this.loginMessage =
            'User does not exist in the system, please contact your administrator'
        })
      })
  }

  scope = {
    graph: ['user.read'],
    api: ['api://' + config.AADConfig.clientId + '/user_impersonation']
  }

  requestState = JSON.stringify({
    redirectUrl:
      typeof window !== 'undefined' ? window.location.origin + '/msal.html' : ''
  })

  clearCache = () => {
    localStorage.clear()
  }

  isInteractionRequired = (error: Error): boolean => {
    if (!error.message || error.message.length <= 0) {
      return false
    }
    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1
    )
  }

  async getAccessToken(scopes: string[]): Promise<string> {
    const tokenrequest = {
      scopes: scopes,
      state: this.requestState
    }
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      var silentResult = await this.msal.acquireTokenSilent(tokenrequest)
      return silentResult.accessToken
    } catch (err: any) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (
        err.name === 'InteractionRequiredAuthError' ||
        this.isInteractionRequired(err)
      ) {
        try {
          var interactiveResult = await this.msal.acquireTokenPopup(
            tokenrequest
          )
          return interactiveResult.accessToken
        } catch {
          runInAction(() => {
            this.shouldLogout = true
            this.isAuth = false
            this.roles = []
            this.isMsalAuth = false
            this.isLoggingIn = false
            this.tokenError = err
          })
          return ''
        }
      } else {
        runInAction(() => {
          this.shouldLogout = true
          this.isAuth = false
          this.roles = []
          this.isMsalAuth = false
          this.isLoggingIn = false
          this.tokenError = err
        })
        return ''
      }
    }
  }

  async getAuthorizationHeader() {
    const accessToken = await this.getAccessToken(this.scope.api)
    return {Authorization: `Bearer ${accessToken}`}
  }
}
