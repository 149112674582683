import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config, {systemObjects} from 'src/config'
import {MainStore} from 'src/store/MainStore'
import CodeObject from '../entities/CodeObject'
import Payment from 'src/entities/payment'
import {downloaFile} from 'src/utils/export'

export class PaymentsStore {
  payments?: Payment[]

  paymentsStatuses: CodeObject[] = []

  isLoadingExportReport?: boolean

  isLoadingSendPaymentEmail?: boolean

  isInvalidPaymentReportId?: boolean

  isDeletePayment?: boolean = false

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isAuth => {
        if (isAuth) {
          this.getPaymentsStatuses()
          this.getPayments()
        }
      }
    )
  }

  setIsLoadingSendPaymentEmail = (isLoading: boolean) => {
    this.isLoadingSendPaymentEmail = isLoading
  }

  setIsInvalidPaymentReportId = (isInvalid: boolean) => {
    this.isInvalidPaymentReportId = isInvalid
  }

  setIsDeletePayment = (isDeletePayment: boolean) => {
    this.isDeletePayment = isDeletePayment
  }

  getPayments = async () => {
    try {
      const res = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments`
      )
      const data = await res.json()
      if (data) {
        runInAction(() => {
          this.payments = data.map((d: any) => {
            return new Payment(d, this.paymentsStatuses)
          })
        })
      }
    } catch (e) {
      console.log('error:', e)
    }
  }

  get decidedPayments() {
    return this.payments?.filter(p => p.statusId === 1) || []
  }

  get decidedPaymentsCount() {
    return this.decidedPayments.length
  }

  get decidedPaymentsSum() {
    return this.decidedPayments.reduce(
      (sum, payment: any) => sum + payment?.amount || 0,
      0
    )
  }

  getPaymentsStatuses = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.paymentStatuses}`
      )
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.paymentsStatuses = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  createPayment = (payment: any) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Payments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payment)
      })
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.payments?.push(
              new Payment(
                {
                  Id: data?.Id,
                  Amount: payment.amount,
                  StatusId: 1,
                  Month: payment.month,
                  Year: payment.year,
                  Comment: payment.comment,
                  InstitutionId: payment.institutionId,
                  CreatedAt: new Date()
                },
                this.paymentsStatuses
              )
            )
          })
        }
      })
      .catch((e: any) => {
        console.log('error: ', e)
        this.owner.setErrorMessage('ההחלטה לא נשמרה, נסה שוב.')
      })
  }

  sendPaymentEmail = async (paymentReportId: number) => {
    return this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Payments/SendPaymentEmails/${paymentReportId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          this.setIsInvalidPaymentReportId(false)
          this.getPayments()
          return true
        }
      })
      .catch((e: any) => {
        if (e.status === 404) {
          this.setIsInvalidPaymentReportId(true)
          return false
        }
        console.log('error: ', e)

        this.owner.setErrorMessage('ארעה שגיאה בשליחת הדו"ח, נסה שוב.')
        return false
      })
      .finally(() => {
        this.setIsLoadingSendPaymentEmail(false)
      })
  }

  updatePayment = (payment: any) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Payments`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payment)
      })
      .then(res => {
        return res.json()
      })
      .then(() => {
        runInAction(() => {
          const paymentToUpdate = this.payments?.find(i => i.id === payment.id)
          if (paymentToUpdate) {
            Object.assign(
              paymentToUpdate,
              new Payment(
                {
                  Id: payment.id,
                  Amount: payment.amount,
                  Month: payment.month,
                  Year: payment.year,
                  Comment: payment.comment,
                  InstitutionId: payment.institutionId,
                  CreatedAt: paymentToUpdate.createdAt,
                  StatusId: paymentToUpdate.statusId
                },
                this.paymentsStatuses
              )
            )
          }
        })
      })
      .catch((e: any) => {
        console.log('error: ', e)
        this.owner.setErrorMessage('ההחלטה לא נשמרה, נסה שוב.')
      })
  }

  updatePaymentStatus = async (payment: Payment, statusId: number) => {
    const prevStatus = payment.statusId
    if (prevStatus === statusId) return

    payment.setStatus(statusId)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/${payment.id}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(statusId)
        }
      )
      const data = await response.json()
      if (data) {
      } else {
        payment.setStatus(prevStatus)
        throw new Error()
      }
    } catch (error) {
      this.owner.setErrorMessage('התהליך נכשל, נסה שוב.')
    }
  }

  setIsLoadingExportReport = (isLoading: boolean) => {
    this.isLoadingExportReport = isLoading
  }

  exportReport = async () => {
    this.setIsLoadingExportReport(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/createPaymentReport`,
        {
          method: 'POST'
        }
      )

      const blob = await response.blob()

      if (blob) {
        downloaFile(blob, 'PaymentsList')
        await this.getPayments()
        this.setIsLoadingExportReport(false)
      }
    } catch (error) {
      this.setIsLoadingExportReport(false)
      this.owner.setErrorMessage('התהליך נכשל, נסה שוב.')
    }
  }

  deletePayment = async (paymentId: number) => {
    this.setIsDeletePayment(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/${paymentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (!response.ok) {
        throw new Error()
      }
      runInAction(() => {
        const newData =
          this.payments?.filter(payment => payment.id !== paymentId) || []
        this.payments = [...newData]
        return true
      })
      this.setIsDeletePayment(false)
    } catch (error) {
      this.setIsDeletePayment(false)
      this.owner.setErrorMessage('מחיקת התשלום נכשלה, נסה שוב.')
    }
    return false
  }
}
