import {useState} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  CircularProgress,
  IconButton
} from '@mui/material'
import Autocomplete from 'src/components/ui/AutoComplete'
import File from 'src/components/ui/File'
import {getFileExtension} from 'src/utils/file'
import {monthsWithId, years} from 'src/utils/month'
import {useMainStore} from 'src/contexts/Main'
import {observer} from 'mobx-react-lite'
import CloseIcon from '@mui/icons-material/Close'
import {ReactComponent as ImageIcon} from 'src/images/image.svg'
import {ReactComponent as ExcelIcon} from 'src/images/excel.svg'
import {ReactComponent as PdfIcon} from 'src/images/pdf.svg'

interface UploadDocDialogProps {
  onClose: () => void
  onSave: (newDoc: any) => void
  categories: any[]
}

const UploadDocDialog = observer(
  ({onClose, categories, onSave}: UploadDocDialogProps) => {
    const {isLoadingUploadDoc} = useMainStore().institutionStore
    const [isMissingCategory, setIsMissingCategory] = useState<boolean>(false)
    const [isMissingFile, setIsMissingFile] = useState<boolean>(false)

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    const [newDoc, setNewDoc] = useState<any>({
      year: currentYear,
      month: currentMonth
    })

    const validateAndSave = () => {
      if (!newDoc.categoryId || !newDoc.file) {
        if (!newDoc.categoryId) {
          setIsMissingCategory(true)
        }
        if (!newDoc.file) {
          setIsMissingFile(true)
        }
        return
      }

      setIsMissingCategory(false)
      setIsMissingFile(false)
      onSave(newDoc)
    }

    const updateDoc = (propertyName: string, value: any) => {
      setNewDoc((prev: any) => ({...prev, [propertyName]: value}))
    }

    const onUpload = (file: File) => {
      updateDoc('name', file.name)
      updateDoc('file', file)
      updateDoc('extension', getFileExtension(file.name))
      setIsMissingFile(false)
    }

    const onDelete = () => {
      updateDoc('name', undefined)
      updateDoc('file', undefined)
      updateDoc('extension', undefined)
      setIsMissingFile(true)
    }

    return (
      <Dialog open fullWidth dir="rtl">
        <DialogTitle>
          <Stack alignItems="center">
            {' '}
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Heebo',
                pb: '2rem',
                fontWeight: 'bold'
              }}
            >
              העלאת מסמך
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <File
              onUpload={onUpload}
              onDelete={onDelete}
              isMissingFile={isMissingFile}
            />

            {newDoc.file && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: '100%',
                  border: '1px solid',
                  borderColor: 'grey.300',
                  borderRadius: '8px',
                  padding: '8px'
                }}
              >
                <IconButton onClick={onDelete}>
                  <CloseIcon />
                </IconButton>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>{newDoc.name}</Typography>
                  {newDoc.extension === 'xlsx' ? (
                    <ExcelIcon />
                  ) : newDoc.extension === 'pdf' ? (
                    <PdfIcon />
                  ) : (
                    <ImageIcon />
                  )}
                </Stack>
              </Stack>
            )}

            <Autocomplete
              dir="rtl"
              options={categories}
              label={'בחירת קטגוריה'}
              onChange={(e: any) => {
                updateDoc('categoryId', e?.id)
                setIsMissingCategory(false)
              }}
              value={newDoc.categoryId}
              error={isMissingCategory}
              errorMessage={'חובה לבחור קטוגריה'}
              getOptionLabel={(option: any) => option.description}
            />

            <FormControl fullWidth>
              <InputLabel dir="rtl">חודש</InputLabel>
              <Select
                fullWidth
                dir="rtl"
                label="חודש"
                onChange={e => updateDoc('month', e.target.value)}
                value={newDoc.month}
              >
                {monthsWithId.map((m: any) => (
                  <MenuItem key={m.id} value={m.id}>
                    {m.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel dir="rtl">שנה</InputLabel>
              <Select
                dir="rtl"
                fullWidth
                label="שנה"
                onChange={e => updateDoc('year', e.target.value)}
                value={newDoc.year}
              >
                {years.map((year: any) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <Stack direction="column" />
        <DialogActions>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Grid item xs={6}>
              <Button variant="outlined" onClick={onClose} fullWidth>
                ביטול
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => validateAndSave()}
                fullWidth
                disabled={isLoadingUploadDoc}
              >
                שמירה
                {isLoadingUploadDoc && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontWeight: 'bold',
                      marginTop: -1.5,
                      marginLeft: -1.5
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
)
export default UploadDocDialog
