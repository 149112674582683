import {forwardRef} from 'react'
import {Link as RouterLink} from 'react-router-dom'
// @mui
import {Tooltip, Link, ListItemText} from '@mui/material'
import Iconify from 'src/components/Iconify'
import {NavItemProps} from '../types'
import {StyledItem, StyledIcon} from './styles'

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({item, depth, open, active, isExternalLink, isSelected, ...other}, ref) => {
    const {title, path, icon, iconSelected, children, disabled, caption} = item

    const subItem = depth !== 1

    const renderContent = (
      <StyledItem
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        {...other}
        sx={{p: 1, width: '135px'}}
      >
        {icon && iconSelected && (
          <StyledIcon>{isSelected ? iconSelected : icon}</StyledIcon>
        )}
        <ListItemText
          primary={title}
          sx={{width: '100%', mr: 1}}
          primaryTypographyProps={{
            // noWrap: true,
            sx: {
              width: '100%',
              fontSize: 14,
              lineHeight: '15px',
              fontWeight: 400,
              textAlign: 'right',
              ...(active && {
                fontWeight: 'fontWeightMedium'
              }),
              color: isSelected ? 'primary' : 'text',
              ...(subItem && {
                fontSize: 14,
                width: 'auto',
                textAlign: 'left'
              })
            }
          }}
        />

        {caption && (
          <Tooltip title={caption} arrow placement="right">
            <Iconify
              icon="eva:info-outline"
              width={16}
              sx={{
                top: 11,
                left: 6,
                position: 'absolute'
              }}
            />
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            width={16}
            icon="eva:chevron-right-fill"
            color="primary"
            sx={{
              top: 11,
              right: 6,
              position: 'absolute'
            }}
          />
        )}
      </StyledItem>
    )

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Link href={path} target="_blank" rel="noopener" underline="none">
            {renderContent}
          </Link>
        )

      // Default
      return (
        <Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>
      )
    }

    return renderItem()
  }
)

export default NavItem
