import React, {useState} from 'react'
import {TextField, IconButton, Stack} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {useMainStore} from 'src/contexts/Main'

const CustomEditComponent = ({
  setOpenDialog,
  id,
  value,
  api,
  field,
  setDialogParams,
  isEditable
}: any) => {
  const [inputValue, setInputValue] = useState(value)
  const [editMode, setEditMode] = useState(false)
  const {isManager} = useMainStore().loginStore

  const handleEditClick = () => {
    setEditMode(true)
  }

  const handleSaveClick = async () => {
    setOpenDialog(true)
    setDialogParams({
      id,
      field,
      value,
      api,
      setEditMode,
      newValue: inputValue,
      setInputValue
    })
  }

  return (
    <Stack direction="row" justifyContent="space-between">
      {editMode ? (
        <TextField
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          style={{textAlign: 'center'}}
        />
      ) : (
        <span>{value}</span>
      )}
      {isManager && isEditable && (
        <IconButton onClick={editMode ? handleSaveClick : handleEditClick}>
          {editMode ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      )}
    </Stack>
  )
}

export default CustomEditComponent
