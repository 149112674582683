import {makeAutoObservable} from 'mobx'

export default class StudentRejection {
  id: number

  firstName?: string

  lastName?: string

  indificationNo: string

  comment?: string

  createdAt?: Date

  constructor(student: any) {
    this.id = student.Id
    this.firstName = student.FirstName
    this.lastName = student.LastName
    this.indificationNo = student.IndificationNo
    this.comment = student.Comment
    this.createdAt = new Date(student.CreatedAt)
    makeAutoObservable(this)
  }
}
