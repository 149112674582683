import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/ModeEdit'
import InstutionTypeSelect from '../Institutions/InstutionsTypeSelect'
import {useMainStore} from '../../contexts/Main'
import IndificationTypeSelect from 'src/components/Institutions/IndificationTypeSelect'
import AssociationBelongsSelect from '../Institutions/AssociationBelongsSelect'
import AutocompleteBank from 'src/components/Institutions/AutoCompleteBank'
import AutoCompleteCity from 'src/components/Institutions/AutoCompleteCity'
import {observer} from 'mobx-react-lite'

//todo: move to utils
const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()

  return `${day}/${month}/${year}`
}

//todo: duplicate code.
const booleanData = [
  {
    id: false,
    name: 'לא'
  },
  {
    id: true,
    name: 'כן'
  }
]

export interface EditableCardField {
  key: string
  name: string
  value: string
  validationRegex?: RegExp
  isMandatory?: boolean
  fullWidth: boolean
}

interface EditableCardProps {
  title: string
  fields: EditableCardField[]
  onSave: (
    updatedFieldState: {[key: string]: string},
    id?: number
  ) => Promise<void>
  object: any
  width?: string
  fieldWidth?: string
  onCancel?: () => void
}
const EditableCard: React.FC<EditableCardProps> = observer(
  ({
    fields,
    onSave,
    title,
    object,
    width,
    fieldWidth,
    onCancel
  }: EditableCardProps) => {
    const {institutionsTypes, indificationTypes, banks, branches, cities} =
      useMainStore().institutionStore
    const [isEditMode, setIsEditMode] = useState(false)
    const [editedObject, setEditedObject] = useState<{[key: string]: any}>({
      ...object
    })
    const [errors, setErrors] = useState<{[key: string]: boolean}>({})

    useEffect(() => {
      setEditedObject({...object})
    }, [object])

    useEffect(() => {
      // Recheck errors whenever the editedObject changes
      const newErrors: {[key: string]: boolean} = {}
      fields.forEach(field => {
        if (
          field.validationRegex &&
          !field.validationRegex.test(editedObject[field.key] || '')
        ) {
          newErrors[field.key] = true
        } else {
          newErrors[field.key] = false
        }
      })
      setErrors(newErrors)
    }, [editedObject, fields])

    const handleEditClick = () => {
      setIsEditMode(true)
      setEditedObject({...object})
    }

    const handleSaveClick = () => {
      setIsEditMode(false)
      onSave(editedObject, object.id)
    }

    const handleCancelClick = () => {
      setIsEditMode(false)
      setEditedObject({...object})
      if (onCancel) onCancel()
    }

    const handleInputChange =
      (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target
        setEditedObject(prevState => ({
          ...prevState,
          [key]: value
        }))
      }

    const updateDate = object.updatedAt
      ? formatDate(object.updatedAt)
      : formatDate(object.createdAt)

    const updateText = 'עודכן ב: ' + updateDate

    const getElementByFieldKey = (key: string, value: any, regex?: RegExp) => {
      const isValid = regex ? new RegExp(regex).test(value as string) : true
      const hasError = errors[key] || !isValid
      switch (key) {
        default:
          return (
            <TextField
              error={hasError}
              helperText={hasError ? 'ערך לא תקין' : ''}
              value={editedObject[key] || ''}
              onChange={handleInputChange(key)}
            />
          )
        case 'typeId':
          return (
            <InstutionTypeSelect
              setEditedObject={setEditedObject}
              currentId={value as number}
            />
          )
        case 'indificationTypeID':
          return (
            <IndificationTypeSelect
              setEditedObject={setEditedObject}
              currentId={value as number}
            />
          )
        case 'isAssociationBelongTo':
          return (
            <AssociationBelongsSelect
              setEditedObject={setEditedObject}
              currentId={value as boolean}
            />
          )
        case 'bankId':
          return (
            <AutocompleteBank
              fieldName={key}
              setEditedObject={setEditedObject}
              currentId={value}
              options={banks}
              error={hasError}
              errorMessage={'חובה לבחור בנק'}
            />
          )
        case 'branchId':
          return (
            <AutocompleteBank
              fieldName={key}
              setEditedObject={setEditedObject}
              currentId={value}
              options={branches}
              error={hasError}
              errorMessage={'חובה לבחור סניף'}
            />
          )
        case 'city':
          return (
            <AutoCompleteCity
              fieldName={key}
              setEditedObject={setEditedObject}
              value={value}
              options={cities}
              error={hasError}
              errorMessage={'חובה לבחור עיר'}
            />
          )
        case 'auditNote':
          return (
            <TextField
              multiline
              error={hasError}
              helperText={hasError ? 'ערך לא תקין' : ''}
              value={editedObject[key] || ''}
              onChange={handleInputChange(key)}
              rows={6}
              fullWidth
              placeholder="הכנס כאן את ההערות שלך... "
            />
          )
      }
    }

    const getValueByFieldKey = (key: string, value: any) => {
      switch (key) {
        default:
          return editedObject[key] || ''
        case 'typeId':
          return institutionsTypes?.find(t => t.id === (value as number))
            ?.description
        case 'indificationTypeID':
          return indificationTypes?.find(i => i.id === (value as number))
            ?.description
        case 'isAssociationBelongTo':
          return booleanData?.find(i => i.id === (value as boolean))?.name
        case 'bankId':
          return banks?.find(b => b.id === value)?.label
        case 'branchId':
          return branches?.find(b => b.id === value)?.label
      }
    }

    const hasErrors = Object.values(errors).some(hasError => hasError)

    return (
      <Card sx={{width: width ? width : 'auto', p: 2, m: 1.5}}>
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="top"
              spacing={1}
              sx={{width: '100%'}}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{flexGrow: 1}}
              >
                <Typography variant="h6">{title}</Typography>
                <IconButton onClick={handleEditClick}>
                  <EditIcon />
                </IconButton>
              </Stack>
              <Typography fontSize={12} color="text.secondary">
                {updateText}
              </Typography>
            </Stack>
          }
        />
        <CardContent>
          <Stack
            sx={{useFlexGap: 'true', flexWrap: 'wrap'}}
            width={'100%'}
            direction={'row'}
            spacing={2}
          >
            {fields.map(field => (
              <Stack
                width={
                  isEditMode ? '100%' : fieldWidth ? fieldWidth : undefined
                }
                minWidth={
                  isEditMode
                    ? '100%'
                    : field.fullWidth === true
                    ? '100%'
                    : undefined
                }
                sx={{p: 2}}
                justifyContent={'space-between'}
                key={field.key}
              >
                <Typography
                  color={theme => theme.palette.grey[500]}
                  fontSize={'12px'}
                  gutterBottom
                >
                  {field.name}
                </Typography>
                {isEditMode ? (
                  getElementByFieldKey(
                    field.key,
                    editedObject[field.key] !== null
                      ? editedObject[field.key]
                      : '',
                    field.validationRegex
                  )
                ) : (
                  <Typography
                    color={theme => theme.palette.grey[900]}
                    sx={{whiteSpace: 'pre-wrap'}}
                    fontSize={'14px'}
                    fontWeight={400}
                  >
                    {getValueByFieldKey(
                      field.key,
                      editedObject[field.key] !== null
                        ? editedObject[field.key]
                        : ''
                    )}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
        </CardContent>
        {isEditMode && (
          <CardActions style={{justifyContent: 'flex-end'}}>
            <Button
              style={{margin: 5}}
              variant="outlined"
              onClick={handleCancelClick}
            >
              ביטול
            </Button>
            <Tooltip
              title={hasErrors ? 'לא ניתן לשמור, ישנם נתונים לא תקינים.' : ''}
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  disabled={hasErrors}
                >
                  שמירה
                </Button>
              </span>
            </Tooltip>
          </CardActions>
        )}
      </Card>
    )
  }
)
export default EditableCard
